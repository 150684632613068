<template>
    <div class="WrapGuess">
        <div class="HeaderGuess">
            <div class="HeaderGuess-title">
                {{ $t('Customers.ListCustomer') }}
            </div>

            <div class="HeaderGuess-actions">
                <div class="btn-show-type1" @click="showAddCustomerTypeModal()">
                    {{ $t('Customers.SegmentManagement') }}
                </div>
                <div class="btn-show mr-16" @click="showCustomerModal()">
                    {{ $t('Customers.AddCustomer') }}
                </div>
                <modal height="auto" name="customer-type-modal" @before-close="beforeCloseCustomerTypeModal">
                    <div class="WrapModal">
                        <div class="HeaderTitle">
                            {{ $t('Customers.SegmentList') }}
                            <span class="material-icons" @click="closeCustomerTypeList()">close</span>
                        </div>
                        <div class="wrapContent mt-16">
                            <div class="wrapheader">
                                <div class="nameSegment w-300">{{ $t('Customers.SegmentName') }}</div>
                                <div class="nameSegment">{{ $t('Customers.NumberOfPeople') }}</div>
                                <div class="nameSegment">{{ $t('Customers.Action') }}</div>
                            </div>
                            <div class="WrapOptionCustom">
                                <div
                                    v-for="customerType in listCustomerType"
                                    :key="customerType.id"
                                    class="OptionCustom"
                                >
                                    <div class="EditOption" v-if="customerType.customerTypeEditing === true">
                                        <input type="text" v-model="customerTypeValue" />
                                        <span
                                            class="material-icons"
                                            @click="updateNewCustomerTypeMethod(customerType.id, customerType.id)"
                                            style="cursor: pointer;"
                                            >check</span
                                        >
                                    </div>
                                    <div class="TypeCustomer w-300" v-if="customerType.customerTypeEditing === false">
                                        {{ customerType.name }}
                                    </div>
                                    <div class="TypeCustomer" v-if="customerType.customerTypeEditing === false">
                                        {{ customerType['totalCustomer'] }}
                                    </div>
                                    <div class="EditIcon">
                                        <span
                                            class="material-icons"
                                            @click="
                                                changeEditCustomerTypeMode(
                                                    customerType.id,
                                                    !customerType.customerTypeEditMode
                                                )
                                            "
                                            v-if="customerType.customerTypeEditMode === false && customerType.id !== ''"
                                            style="cursor: pointer;"
                                            >more_vert
                                        </span>
                                        <div
                                            class="EditOption"
                                            v-if="
                                                customerType.customerTypeEditMode === true &&
                                                    customerType.customerTypeEditing === true &&
                                                    customerType.id !== ''
                                            "
                                        >
                                            <span
                                                class="material-icons"
                                                @click="
                                                    changeCustomerType(
                                                        customerType.id,
                                                        !customerType.customerTypeEditing
                                                    )
                                                "
                                                style="cursor: pointer; color: #FF4343 !important;"
                                            >
                                                close
                                            </span>
                                        </div>
                                        <div
                                            class="EditIcon"
                                            v-if="
                                                customerType.customerTypeEditMode === true &&
                                                    customerType.customerTypeEditing === false &&
                                                    customerType.id !== ''
                                            "
                                        >
                                            <span
                                                class="material-icons ColorEdit"
                                                @click="
                                                    changeCustomerType(
                                                        customerType.id,
                                                        !customerType.customerTypeEditing
                                                    )
                                                "
                                                style="cursor: pointer;"
                                            >
                                                edit
                                            </span>
                                            <span
                                                class="material-icons ColorDelete"
                                                @click="deleteCustomerType(customerType.id, customerType.name)"
                                                style="cursor: pointer;"
                                            >
                                                delete
                                            </span>
                                            <span
                                                class="material-icons"
                                                @click="
                                                    changeEditCustomerTypeMode(
                                                        customerType.id,
                                                        !customerType.customerTypeEditMode
                                                    )
                                                "
                                                style="cursor: pointer;"
                                            >
                                                more_vert
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="EditOption positionOption bg-White" v-if="addingCustomerType === true">
                                    <input type="text" v-model="customerTypeAddingValue" />
                                    <span
                                        class="material-icons"
                                        style="cursor: pointer"
                                        @click="addNewCustomerTypeMethod()"
                                        >check</span
                                    >
                                    <span
                                        class="material-icons"
                                        style="cursor: pointer; color: #FF4343 !important;"
                                        @click="addingCustomerType = !addingCustomerType"
                                        >close</span
                                    >
                                </div>
                            </div>
                            <div class="OptionEnd mt-16" @click="addingCustomerType = !addingCustomerType">
                                <span class="mr-16 material-icons">add</span>
                                {{ $t('Customers.AddNewSegment') }}
                            </div>
                        </div>
                    </div>
                </modal>
                <modal height="auto" name="customer-modal" @before-close="beforeCloseCustomerModal">
                    <div class="header-img">
                        <div>
                            <img
                                v-if="customerImageInModal !== ''"
                                :src="customerImageInModal"
                                alt="image"
                                height="50"
                            />
                        </div>
                        <div class="InputCustom" style="cursor: pointer;">
                            <span class="material-icons" style="cursor: pointer;">add</span>
                            <input type="file" @change="handleFileUpload($event)" style="cursor: pointer;" />
                            {{ $t('Customers.AddPicture') }}<span style="color: #ff5050">&nbsp;&nbsp;&#42;</span>
                        </div>
                    </div>
                    <div class="sectionPogup">
                        <div class="wrapSelectNamePhone">
                            <div class="SelectName">
                                <span class="LabelInput"
                                    >{{ $t('Customers.FullName')
                                    }}<span style="color: #ff5050">&nbsp;&nbsp;&#42;</span></span
                                >

                                <input
                                    type="text"
                                    :placeholder="$t('Customers.TypeName')"
                                    class="InputCustomPogup mt-8px"
                                    v-model="customerNameInModal"
                                />
                                <span
                                    style="display: flex; align-items: center; margin-left: 10px"
                                    v-if="validateMessage['customerName']"
                                >
                                    <span
                                        class="material-icons"
                                        style="color: #ff5050; font-size:1.0vw; margin-right: 3px"
                                        >cancel</span
                                    >
                                    <span style="color: red; font-size:0.8vw; ">
                                        {{ validateMessage['customerName'] }}
                                    </span>
                                </span>
                            </div>
                            <div class="SelectPhone">
                                <span class="LabelInput">{{ $t('Customers.Phone') }}</span>
                                <input
                                    type="text"
                                    :placeholder="$t('Customers.TypeTelephone')"
                                    class="InputCustomPogup mt-8px"
                                    v-model="customerTelephoneInModal"
                                />
                                <span
                                    style="display: flex; align-items: center; margin-left: 10px"
                                    v-if="validateMessage['customerPhone']"
                                >
                                    <span
                                        class="material-icons"
                                        style="color: #ff5050; font-size:1.0vw; margin-right: 3px"
                                        >cancel</span
                                    >
                                    <span style="color: red; font-size:0.8vw; ">
                                        {{ validateMessage['customerPhone'] }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="wrapSelectNamePhone mt-16">
                            <div class="SelectName">
                                <span class="LabelInput">
                                    {{ $t('Customers.Gender') }}
                                </span>
                                <div
                                    class="SelectCustomPogup mt-8px"
                                    @click="chooseGenderOptionInModal()"
                                    v-click-outside="handleClickOutSideGenderInModal"
                                >
                                    {{ genderTitleInModal }}
                                    <span class="material-icons">arrow_drop_down</span>
                                    <div ref="wrapGenderOption" class="wrapOption">
                                        <div class="Option" @click="changeGenderInModal('Male', 'Nam')">
                                            {{ $t('dashboard.Male') }}
                                        </div>
                                        <div class="Option" @click="changeGenderInModal('Female', 'Nữ')">
                                            {{ $t('dashboard.Female') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="SelectPhone">
                                <span class="LabelInput">{{ $t('Customers.DateOfBirth') }}</span>
                                <date-picker
                                    @click.native="chooseTime()"
                                    class="mt-8px"
                                    v-model="rangeTime"
                                    :placeholder="rangeTimeString"
                                    :lang="langData === 'vn' ? 'vi' : 'en'"
                                    v-click-outside="handleClickOutsideRangePicker"
                                    format="DD/MM/YYYY"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="wrapSelectNamePhone mt-16">
                            <div class="SelectName width100">
                                <span class="LabelInput">
                                    {{ $t('Customers.Segment') }}
                                </span>
                                <div
                                    class="SelectCustomPogup mt-8px"
                                    @click="chooseCustomerTypeOptionInModal()"
                                    v-click-outside="handleClickOutSideCustomerTypeInModal"
                                >
                                    {{ customerTypeTitleInModal }}
                                    <span class="material-icons">arrow_drop_down</span>
                                    <div class="wrapOptionSegment" ref="wrapCustomerTypeOption">
                                        <div v-for="customerType in listCustomerTypeInAdd" :key="customerType.id">
                                            <div
                                                :ref="customerType.id"
                                                class="Option"
                                                @click="changeCustomerTypeInModal(customerType.name, customerType.id)"
                                            >
                                                {{ customerType.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="linkSeeHistory"
                        v-if="editingCustomer"
                        @click="
                            callGetCustomerRecordByCusID(
                                customerIDInModal,
                                customerNameInModal,
                                customerImageInModal,
                                page
                            )
                        "
                    >
                        {{ $t('Customers.ShowHistory') }}
                    </div>
                    <div class="BottomPogup">
                        <div class="wrapaddCSV">
                            <div class="btn-addCSV" v-show="editingCustomer === false">
                                {{ $t('Customers.AddCustomerByExcel') }}
                                <input type="file" @change="addCustomerByExcel()" ref="excelFile" />
                            </div>
                            <div class="dowload ml-16" v-show="editingCustomer === false">
                                <md-tooltip class="z-index9999">
                                    {{ $t('Customers.TemplateFile') }}
                                </md-tooltip>
                                <!-- <a download="door" href="/img/default-avatar.png"><span class="material-icons">file_download</span></a> -->
                                <div class="wrapDownload" @click="downloadTemplateAPI()">
                                    <span class="material-icons">file_download</span>
                                </div>
                            </div>
                        </div>
                        <div class="wrapSaveCancel">
                            <div class="Btn-Close mr-16" @click="hideCustomerModal()">
                                {{ $t('Settings.Cancel') }}
                            </div>
                            <div class="Btn-Save" @click="callAPIAddCustomer()">
                                {{ $t('Customers.Submit') }}
                            </div>
                        </div>
                    </div>
                </modal>
            </div>
        </div>
        <div class="WrapSection">
            <div class="WrapBtn">
                <div class="btn-search">
                    <span @click="searchForCustomer()" class="material-icons">
                        search
                    </span>
                    <input
                        type="text"
                        :placeholder="$t('Customers.SearchHistory')"
                        v-model="searchValue"
                        v-on:keyup.enter="searchForCustomer()"
                    />
                </div>
                <div
                    class="btn-filter mr-16 ml-16"
                    v-bind:class="{ ActiveButton: filterMode, 'btn-filter': !filterMode }"
                    @click="turnFilter()"
                >
                    <span class="material-icons">filter_alt</span>
                    Filter
                </div>
                <div class="btn-delete" v-show="listCustomerIDChecked.length !== 0" @click="deleteManyCustomer()">
                    <span class="material-icons ColorDelete">delete</span>
                </div>
                <div class="btn-filter ml-auto" @click="downloadListCustomer()">
                    <span class="material-icons">download</span>
                    {{ $t('Report.DownloadReport') }}
                </div>
            </div>
            <div class="SelectFilter" v-show="filterMode === true">
                <div
                    class="selectCustom mr-16"
                    @click="chooseAgeFilter()"
                    v-click-outside="handleClickOutsideAgeFilter"
                    v-bind:class="{ ActiveButton: ageRangeAvailable }"
                >
                    <span class="Texoverflow"> {{ ageArr.length > 0 ? ageArr.toString() : ageFilterResult }}</span>
                    <span class="material-icons">arrow_drop_down</span>
                    <div ref="wrapAgeFilterOption" class="wrapOption">
                        <div style="padding: 8px 16px" v-for="(age, index) in ageOptions" :key="index">
                            <input
                                id="chk"
                                style="cursor: pointer; margin-right: 8px"
                                type="checkbox"
                                :checked="age.isChecked"
                                :value="age.text"
                                @click="selectAge(age.text)"
                            />
                            {{ age.text !== '60 - 100' ? age.text : '> 60' }}
                        </div>
                    </div>
                </div>

                <div
                    class="selectCustom mr-16"
                    @click="chooseGenderFilter()"
                    v-click-outside="handleClickOutsideGenderFilter"
                    v-bind:class="{ ActiveButton: genderAvailable }"
                >
                    <span class="Texoverflow">
                        {{
                            genArr.length > 0
                                ? genArr
                                      .map(el => (el === 'male' ? $t('dashboard.Male') : $t('dashboard.Female')))
                                      .toString()
                                : genderFilterResult
                        }}
                    </span>
                    <span class="material-icons">arrow_drop_down</span>
                    <div ref="wrapGenderFilterOption" class="wrapOption">
                        <div style="padding: 8px 16px" v-for="(gender, index) in genderOptions" :key="index">
                            <input
                                id="chkd"
                                style="cursor: pointer; margin-right: 8px"
                                type="checkbox"
                                :checked="gender.isChecked"
                                :value="gender.gen"
                                @click="selectCustomerGender(gender.gen)"
                            />
                            {{ gender.gen !== 'male' ? $t('dashboard.Female') : $t('dashboard.Male') }}
                        </div>
                    </div>
                </div>
                <div
                    class="selectCustom"
                    @click="chooseCustomerTypeFilter()"
                    v-click-outside="handleClickOutsideCustomerTypeFilter"
                    v-bind:class="{ ActiveButton: segmentAvailable }"
                >
                    <span class="Texoverflow">
                        {{ customerTypeDisplay || customerTypeResult }}
                    </span>
                    <span class="material-icons">arrow_drop_down</span>
                    <div ref="wrapCustomerTypeFilterOption" class="wrapOption">
                        <div
                            style="padding: 8px 16px; display: flex"
                            v-for="(customerType, index) in listCustomerSegmentFilter"
                            :key="index"
                        >
                            <input
                                id="chkk"
                                style="cursor: pointer; margin-right: 8px"
                                type="checkbox"
                                :checked="customerType.isChecked"
                                :value="customerType.id"
                                @click="selectCustomerTypeData(customerType.id)"
                            />
                            {{ customerType.name }}
                        </div>
                    </div>
                </div>
                <div class="CloserFilter">
                    <span class="material-icons" @click="resetFilter()">
                        filter_alt_off
                    </span>
                </div>
            </div>
            <div class="WrapTable">
                <table id="customers">
                    <tr class="tr-head">
                        <th></th>
                        <th style="cursor: pointer;" @click="changeSortName(!ascendingNameValue)">
                            {{ $t('Customers.FullName') }}
                            <span class="material-icons" v-if="ascendingNameValue === true">arrow_drop_down</span>
                            <span class="material-icons" v-else>arrow_drop_up</span>
                        </th>
                        <th style="cursor: pointer;" @click="changeSortGender(!ascendingGenderValue)">
                            {{ $t('Customers.Gender') }}
                            <span class="material-icons" v-if="ascendingGenderValue === true">arrow_drop_up</span>
                            <span class="material-icons" v-else>arrow_drop_down</span>
                        </th>
                        <th style="cursor: pointer;" @click="changeSortAge(!ascendingAgeValue)">
                            {{ $t('Customers.Age') }}
                            <span class="material-icons" v-if="ascendingAgeValue === true">arrow_drop_up</span>
                            <span class="material-icons" v-else>arrow_drop_down</span>
                        </th>
                        <th style="cursor: pointer;" @click="changeSortNumberOfVisits(!ascendingVisitNumberValue)">
                            {{ $t('Customers.NumberOfVisits') }}
                            <span class="material-icons" v-if="ascendingVisitNumberValue === false"
                                >arrow_drop_down</span
                            >
                            <span class="material-icons" v-else>arrow_drop_up</span>
                        </th>
                        <th style="cursor: pointer;" @click="changeSortSegment(!ascendingSegmentValue)">
                            {{ $t('Customers.Segment') }}
                            <span class="material-icons" v-if="ascendingSegmentValue === true">arrow_drop_down</span>
                            <span class="material-icons" v-else>arrow_drop_up</span>
                        </th>
                        <th>{{ $t('Customers.Edit') }}</th>
                    </tr>
                    <tr v-for="customer in listCustomerData" :key="customer.id">
                        <td class="border-bottom border-left">
                            <input
                                type="checkbox"
                                :value="customer.id"
                                v-model="customer.customerIDChecked"
                                v-on:input="customerCheckFunction(customer.id, !customer.customerIDChecked)"
                            />
                        </td>
                        <td
                            class="border-bottom customer-name-width"
                            style="cursor: pointer;"
                            @click="callGetDetailCustomerInAvatar(customer.id)"
                        >
                            <img :src="customer.image" alt="image" />
                            {{ customer.name }}
                            <md-tooltip md-direction="top">{{ customer.name }}</md-tooltip>
                        </td>
                        <td class="border-bottom">{{ customer.gender }}</td>
                        <td class="border-bottom">{{ customer.age }}</td>
                        <td class="border-bottom">{{ customer.visitNumber }}</td>
                        <td class="border-bottom">{{ customer.customerType }}</td>
                        <td class="border-bottom border-right">
                            <span
                                class="material-icons"
                                @click="changeEditCustomerMode(customer.id, !customer.customerEdit)"
                                v-if="customer.customerEdit === false"
                            >
                                more_vert
                            </span>
                            <div v-if="customer.customerEdit === true">
                                <span class="material-icons ColorEdit" @click="showEditCustomerModal(customer.id)">
                                    edit
                                </span>
                                <span class="material-icons ColorDelete" @click="callDeleteCustomerAPI(customer.id)">
                                    delete
                                </span>
                                <span
                                    class="material-icons"
                                    @click="changeEditCustomerMode(customer.id, !customer.customerEdit)"
                                >
                                    more_vert
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="pagination">
                    <div class="wrapSelectPagination">
                        <span class="seeTable"> {{ $t('Customers.RowPerPage') }}</span>
                        <div
                            class="SelectPagination"
                            @click="chooseLimitFilter()"
                            v-click-outside="handleClickOutsideLimitFilter"
                        >
                            {{ limit }}
                            <span class="material-icons">arrow_drop_down</span>
                            <div class="wrapOptionPagination" ref="wrapLimitFilterOption">
                                <div class="OptionActive" ref="limitFive" @click="changeLimit('5', 'limitFive')">
                                    5
                                </div>
                                <div class="Option" ref="limitTen" @click="changeLimit('10', 'limitTen')">
                                    10
                                </div>
                                <div class="Option" ref="limitFifteen" @click="changeLimit('15', 'limitFifteen')">
                                    15
                                </div>
                                <div class="Option" ref="limitTwenty" @click="changeLimit('20', 'limitTwenty')">
                                    20
                                </div>
                            </div>
                        </div>
                    </div>
                    <a style="cursor: pointer;" v-show="page !== 1" @click="changePage(0)">
                        <span class="material-icons">first_page</span>
                    </a>
                    <a
                        style="cursor: pointer;"
                        v-show="page !== 1 && page <= parseInt(totalPage) && page > 0"
                        @click="changePage(-1)"
                    >
                        <span class="material-icons">navigate_before</span>
                    </a>
                    <a
                        style="cursor: pointer;"
                        @click="changePage(-1)"
                        v-if="page !== 1 && page === parseInt(totalPage) && page > 0"
                    >
                        {{ page - 1 }}
                    </a>
                    <a style="cursor: pointer;" class="active">{{ page }}</a>

                    <a
                        style="cursor: pointer;"
                        @click="changePage(1)"
                        v-if="page !== parseInt(totalPage) && page < parseInt(totalPage)"
                    >
                        {{ page + 1 }}
                    </a>
                    <a style="cursor: pointer;" v-show="page !== parseInt(totalPage)" @click="changePage(1)">
                        <span class="material-icons">navigate_next</span>
                    </a>
                    <a
                        style="cursor: pointer;"
                        v-show="page !== parseInt(totalPage)"
                        @click="changePage(parseInt(totalPage))"
                    >
                        <span class="material-icons">last_page</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Api } from '@/api';
import * as moment from 'moment-timezone';

const getListCustomer = Api.get('getListCustomerV2');
const listCustomerTypeAPI = Api.get('listCustomerType');
const addCustomer = Api.get('addCustomerV2');
const getCustomerDetailV2 = Api.get('getCustomerDetailV2');
const deleteCustomer = Api.get('deleteCustomerV2');
const addCustomerTypeAPI = Api.get('addCustomerType');
const updateCustomerTypeAPI = Api.get('updateCustomerType');
const deleteCustomerTypeAPI = Api.get('deleteCustomerType');
const downloadListCustomerAPI = Api.get('downloadListCustomer');
const updateCustomerAPI = Api.get('updateCustomerV2');
const deleteManyCustomerAPI = Api.get('deleteManyCustomer');
const addCustomerByExcelAPI = Api.get('addCustomerByExcel');
const downloadTemplateFile = Api.get('downloadTemplateFile');

const ListDatepickerClass = [
    'option rangeTime',
    'mx-icon-right',
    'mx-icon-left',
    'mx-icon-double-left',
    'mx-icon-double-right',
    'mx-calendar-header',
    'mx-btn mx-btn-text mx-btn-icon-double-left',
    'mx-btn mx-btn-text mx-btn-icon-double-right',
    'mx-btn mx-btn-text mx-btn-icon-left',
    'mx-btn mx-btn-text mx-btn-icon-right',
    'mx-calendar mx-calendar-panel-date',
    'mx-calendar mx-calendar-panel-date',
];

export default {
    name: 'ManageCustomer',
    methods: {
        downloadTemplateAPI() {
            const lang = localStorage.lang;
            downloadTemplateFile.post(lang).then(response => {
                const url = URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'application/vnd.ms-excel',
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'add customer template.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },
        selectAge(age) {
            this.ageArr.includes(age) ? (this.ageArr = this.ageArr.filter(s => s !== age)) : this.ageArr.push(age);
            this.ageOptions.forEach(elem => {
                if (elem.text === age) {
                    elem.isChecked = !elem.isChecked;
                }
            });
            this.page = 1;
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        selectCustomerGender(gender) {
            this.genArr.includes(gender)
                ? (this.genArr = this.genArr.filter(s => s !== gender))
                : this.genArr.push(gender);
            this.genderOptions.forEach(elem => {
                if (elem.gen === gender) {
                    elem.isChecked = !elem.isChecked;
                }
            });
            this.page = 1;
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        selectCustomerTypeData(customerTypeID) {
            this.listCustomerSegmentFilter.forEach(elm => {
                if (elm.id === customerTypeID) {
                    elm.isChecked = !elm.isChecked;
                }
            });
            if (customerTypeID === 'Chưa có phân khúc' || customerTypeID === 'No Segment') {
                customerTypeID = '';
            }
            this.customerTypeArr.includes(customerTypeID)
                ? (this.customerTypeArr = this.customerTypeArr.filter(s => s !== customerTypeID))
                : this.customerTypeArr.push(customerTypeID);

            let listCustomerTypeName = [];
            this.customerTypeArr.forEach(customerTypeID => {
                const customerType = this.listCustomerType.find(customerType => customerType.id === customerTypeID);
                listCustomerTypeName.push(customerType.name);
            });

            this.customerTypeDisplay = listCustomerTypeName.join(',');

            this.page = 1;
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                1,
                this.limit
            );
        },
        resetFilter() {
            this.customerTypeResult = this.$t('Customers.TypeCustomerType');
            this.customerTypeFilter = '';
            this.sortAttribute = 'name';
            this.ascending = true;
            this.ageFilter = '';
            this.genderFilter = '';
            this.genderFilterResult = this.$t('Customers.TypeGender');
            this.ageArr = [];
            this.genArr = [];
            this.customerTypeArr = [];
            this.ageOptions = this.ageOptions.map(el => ({ ...el, ...{ isChecked: false } }));
            this.genderOptions = this.genderOptions.map(el => ({ ...el, ...{ isChecked: false } }));
            this.listCustomerSegmentFilter = this.listCustomerSegmentFilter.map(customerType => {
                customerType.isChecked = false;
                return customerType;
            });
            this.page = 1;
            this.customerTypeDisplay = '';
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genderFilter,
                this.ageArr,
                this.customerTypeFilter,
                this.page,
                this.limit
            );
        },
        callGetDetailCustomerInAvatar(customerID) {
            const self = this;
            getCustomerDetailV2.get(customerID).then(response => {
                const customerDetailResponse = response.data;

                const customerType = self.listCustomerType.find(customerType => {
                    return customerType.id === customerDetailResponse.customerType;
                });

                if (customerDetailResponse.customerType !== '') {
                    self.customerTypeTitleInModal = customerType.name;
                    self.customerTypeInModal = customerDetailResponse.customerType;
                } else {
                    self.customerTypeTitleInModa = this.$t('Customers.TypeCustomerType');
                }

                self.customerNameInModal = customerDetailResponse.name;
                self.customerAgeInModal = customerDetailResponse.age;
                // self.rangeTime = 1666311641000;
                self.rangeTimeString = customerDetailResponse.birthDate;
                self.customerTelephoneInModal = Boolean(customerDetailResponse.telephone)
                    ? customerDetailResponse.telephone
                    : '';
                self.customerImageInModal = customerDetailResponse.image;
                self.customerIDInModal = customerID;

                if (customerDetailResponse.gender === 'male') {
                    self.genderTitleInModal = self.$t('dashboard.Male');
                } else {
                    self.genderTitleInModal = self.$t('dashboard.Female');
                }
                self.customerGenderInModal = customerDetailResponse.gender;
                self.editingCustomer = true;
                self.$modal.show('customer-modal');
            });
        },
        callGetCustomerRecordByCusID(cusID, cusName, cusImage, page) {
            this.$router.push({
                path: 'report-face',
                query: {
                    cusID: cusID,
                    meta: true,
                },
            });
            localStorage.setItem(
                'currentCustomerData',
                JSON.stringify({
                    cusName: cusName,
                    cusImage: cusImage,
                    cusID: cusID,
                    page: page,
                    searchValue: this.searchValue,
                    sortAttribute: this.sortAttribute,
                    ascending: this.ascending,
                    genderFilter: this.genArr.toString(),
                    ageFilter: this.ageArr.toString(),
                    customerTypeFilter: this.customerTypeArr.toString(),
                    limit: this.limit,
                })
            );
        },
        updateNewCustomerTypeMethod(customerTypeID, oldCustomerTypeID) {
            var self = this;
            var dataOfNewUpdatedCustomerType = {
                name: this.customerTypeValue.replace(/  +/g, ' '),
            };
            this.$swal({
                title: this.$t('CustomerResponse.CM99999'),
                allowOutsideClick: true,
            });
            this.$swal.showLoading();
            updateCustomerTypeAPI
                .put(customerTypeID, dataOfNewUpdatedCustomerType)
                .then(response => {
                    this.$swal.close();
                    listCustomerTypeAPI.get().then(response => {
                        let listCustomerType = response.data['listCustomerTypeResponse'];

                        for (let i = 0; i < listCustomerType.length; i++) {
                            listCustomerType[i].customerTypeEditMode = false;
                            listCustomerType[i].customerTypeEditing = false;
                            listCustomerType[i].isChecked = false;
                            self.customerTypeValue = '';
                        }
                        self.listCustomerType = listCustomerType;
                        self.addingCustomerType = false;
                        self.listCustomerSegmentFilter = self.listCustomerSegmentFilter.map(segment => {
                            if (segment.id === customerTypeID) {
                                segment.name = dataOfNewUpdatedCustomerType['name'];
                            }
                            return segment;
                        });
                        self.listCustomerTypeInAdd = listCustomerType.filter(s => s.id !== '');
                        self.updateCustomerTypeDisplay(self.customerTypeArr);
                        self.callGetListCustomer(
                            self.searchValue,
                            self.sortAttribute,
                            self.ascending,
                            self.genArr.toString(),
                            self.ageArr.toString(),
                            self.customerTypeArr.toString(),
                            self.page,
                            self.limit
                        );
                    });
                    self.$swal(self.$t(`CustomerResponse.${response.data.message.replace('.', '')}`), '', 'success');
                })
                .catch(error => {
                    this.$swal.close();
                    this.$swal(
                        self.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                        '',
                        'warning'
                    );
                });
        },
        updateCustomerTypeDisplay(customerTypeArr) {
            if (customerTypeArr.length > 0) {
                let listCustomerTypeName = [];
                customerTypeArr.forEach(customerTypeID => {
                    const customerType = this.listCustomerType.find(customerType => customerType.id === customerTypeID);
                    listCustomerTypeName.push(customerType.name);
                });
                this.customerTypeDisplay = listCustomerTypeName.join(',');
            }
        },
        deleteCustomerType(customerTypeID, oldCustomerTypeName) {
            let self = this;
            this.$swal({
                title: this.$t('Customers.DeleteCustomerTypeText'),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: this.$t('Customers.AcceptToDelete'),
                cancelButtonText: this.$t('Customers.Cancel'),
            }).then(result => {
                if (result.value) {
                    this.$swal({
                        title: this.$t('CustomerResponse.CM99999'),
                        allowOutsideClick: true,
                    });
                    this.$swal.showLoading();
                    deleteCustomerTypeAPI
                        .delete(customerTypeID)
                        .then(response => {
                            this.$swal.close();
                            listCustomerTypeAPI.get().then(response => {
                                let listCustomerType = response.data['listCustomerTypeResponse'];
                                for (let i = 0; i < listCustomerType.length; i++) {
                                    listCustomerType[i].customerTypeEditMode = false;
                                    listCustomerType[i].customerTypeEditing = false;
                                    self.customerTypeValue = '';
                                }
                                self.listCustomerType = listCustomerType;
                                self.listCustomerSegmentFilter = self.listCustomerSegmentFilter.filter(
                                    element => element.id !== customerTypeID
                                );
                                self.addingCustomerType = false;
                                self.listCustomerTypeInAdd = listCustomerType.filter(s => s.id !== '');
                                if (self.customerTypeArr.includes(customerTypeID)) {
                                    self.customerTypeArr = self.customerTypeArr.filter(
                                        element => element !== customerTypeID
                                    );
                                }
                                self.updateCustomerTypeDisplay(self.customerTypeArr);

                                this.callGetListCustomer(
                                    this.searchValue,
                                    this.sortAttribute,
                                    this.ascending,
                                    this.genArr.toString(),
                                    this.ageArr.toString(),
                                    this.customerTypeArr.toString(),
                                    this.page,
                                    this.limit
                                );
                            });
                            this.$swal(
                                self.$t(`CustomerResponse.${response.data.message.replace('.', '')}`),
                                '',
                                'success'
                            );
                        })
                        .catch(error => {
                            this.$swal(
                                self.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                        });
                }
            });
        },

        addNewCustomerTypeMethod() {
            let self = this;
            let dataOfNewCustomerType = {
                name: this.customerTypeAddingValue.replace(/  +/g, ' '),
            };
            addCustomerTypeAPI
                .post(dataOfNewCustomerType)
                .then(response => {
                    self.customerTypeAddingValue = '';
                    self.$swal(self.$t(`CustomerResponse.${response.data.message.replace('.', '')}`), '', 'success');
                })
                .then(_ => {
                    listCustomerTypeAPI.get().then(response => {
                        let listCustomerType = response.data['listCustomerTypeResponse'];

                        for (let i = 0; i < listCustomerType.length; i++) {
                            listCustomerType[i].customerTypeEditMode = false;
                            listCustomerType[i].customerTypeEditing = false;
                            self.customerTypeValue = '';
                        }
                        self.listCustomerType = listCustomerType;
                        self.addingCustomerType = false;
                        let getNewSegmentAddedByUser = listCustomerType.filter(
                            element => element['name'] === dataOfNewCustomerType['name']
                        );
                        self.listCustomerSegmentFilter = self.listCustomerSegmentFilter.concat(
                            getNewSegmentAddedByUser
                        );

                        self.listCustomerTypeInAdd = listCustomerType.filter(s => s.id !== '');
                        this.callGetListCustomer(
                            this.searchValue,
                            this.sortAttribute,
                            this.ascending,
                            this.genArr.toString(),
                            this.ageArr.toString(),
                            this.customerTypeArr.toString(),
                            this.page,
                            this.limit
                        );
                    });
                })
                .catch(error => {
                    this.$swal.close();
                    this.$swal(
                        self.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                        '',
                        'warning'
                    );
                });
        },

        changeCustomerType(customerTypeID, input) {
            if (input === false) {
                for (var i = 0; i < this.listCustomerType.length; i++) {
                    if (this.listCustomerType[i].id === customerTypeID) {
                        this.listCustomerType[i].customerTypeEditing = false;
                        this.customerTypeValue = '';
                    }
                }
            } else {
                for (var i = 0; i < this.listCustomerType.length; i++) {
                    if (this.listCustomerType[i].id === customerTypeID) {
                        this.listCustomerType[i].customerTypeEditing = true;
                        this.customerTypeValue = this.listCustomerType[i].name;
                    } else {
                        this.listCustomerType[i].customerTypeEditing = false;
                        this.listCustomerType[i].customerTypeEditMode = false;
                    }
                }
            }
        },
        addCustomerByExcel() {
            var formData = new FormData();
            formData.append('file', this.$refs['excelFile'].files[0]);
            this.$swal({
                title: this.$t('CustomerResponse.CM99999'),
                allowOutsideClick: true,
            });
            this.$swal.showLoading();
            addCustomerByExcelAPI
                .post(formData)
                .then(result => {
                    this.$swal.close();
                    if (result.headers['content-type'] === 'application/json; charset=utf-8') {
                        this.$swal(this.$t('CustomerResponse.CM00020'), '', 'success');
                    } else {
                        const url = URL.createObjectURL(
                            new Blob([result.data], {
                                type: 'application/vnd.ms-excel',
                            })
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'responseFile.xlsx');
                        document.body.appendChild(link);
                        link.click();

                        this.$swal(this.$t('CustomerResponse.CM99996'), '', 'success');
                    }

                    this.callGetListCustomer(
                        this.searchValue,
                        this.sortAttribute,
                        this.ascending,
                        this.genArr.toString(),
                        this.ageArr.toString(),
                        this.customerTypeArr.toString(),
                        this.page,
                        this.limit
                    );
                })
                .catch(error => {
                    if (error.response === undefined) {
                        this.$swal.close();
                        this.$swal(this.$t('CustomerResponse.CM99995'), '', 'warning');
                    } else {
                        this.$swal.close();
                        this.$swal(this.$t('CustomerResponse.CM00019'), '', 'warning');
                    }
                });
        },
        searchForCustomer() {
            this.page = 1;
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        closeCustomerTypeList() {
            this.$modal.hide('customer-type-modal');
        },
        deleteManyCustomer() {
            var dataSendingToDeleteCustomer = {
                customerID: this.listCustomerIDChecked,
            };
            this.$swal({
                title: this.$t('Customers.DeleteCustomerText'),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: this.$t('Customers.AcceptToDelete'),
                cancelButtonText: this.$t('Customers.Cancel'),
            }).then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.value) {
                    this.$swal({
                        title: this.$t('CustomerResponse.CM99999'),
                        allowOutsideClick: true,
                    });
                    this.$swal.showLoading();
                    deleteManyCustomerAPI
                        .post(dataSendingToDeleteCustomer)
                        .then(response => {
                            this.$swal.close();
                            this.$swal(
                                this.$t(`CustomerResponse.${response.data.message.replace('.', '')}`),
                                '',
                                'success'
                            );

                            this.page = 1;
                            this.callGetListCustomer(
                                this.searchValue,
                                this.sortAttribute,
                                this.ascending,
                                this.genArr.toString(),
                                this.ageArr.toString(),
                                this.customerTypeArr.toString(),
                                this.page,
                                this.limit
                            );
                            this.listCustomerIDChecked = [];
                        })
                        .catch(error => {
                            this.$swal(this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`));
                        });
                }
            });
        },
        changeSortName(ascendingNameValue) {
            this.sortAttribute = 'name';
            this.ascendingNameValue = ascendingNameValue;
            this.ascending = this.ascendingNameValue;
            this.ascendingAgeValue = false;
            this.ascendingGenderValue = false;
            this.ascendingVisitNumberValue = false;
            this.ascendingSegmentValue = true;

            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        changeSortAge(ascendingAgeValue) {
            this.sortAttribute = 'age';
            this.ascendingAgeValue = ascendingAgeValue;
            this.ascending = this.ascendingAgeValue;

            this.ascendingNameValue = true;
            this.ascendingGenderValue = false;
            this.ascendingVisitNumberValue = false;
            this.ascendingSegmentValue = true;

            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        changeSortGender(ascendingGenderValue) {
            this.sortAttribute = 'gender';
            this.ascendingGenderValue = ascendingGenderValue;
            this.ascending = this.ascendingGenderValue;

            this.ascendingNameValue = true;
            this.ascendingAgeValue = false;
            this.ascendingVisitNumberValue = false;
            this.ascendingSegmentValue = true;

            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        changeSortNumberOfVisits(ascendingVisitNumberValue) {
            this.sortAttribute = 'visitNumber';
            this.ascendingVisitNumberValue = ascendingVisitNumberValue;
            this.ascending = this.ascendingVisitNumberValue;

            this.ascendingNameValue = true;
            this.ascendingAgeValue = false;
            this.ascendingGenderValue = false;
            this.ascendingSegmentValue = true;

            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        changeSortSegment(ascendingSegmentValue) {
            this.sortAttribute = 'customerType';
            this.ascendingSegmentValue = ascendingSegmentValue;
            this.ascending = this.ascendingSegmentValue;

            this.ascendingNameValue = true;
            this.ascendingAgeValue = false;
            this.ascendingGenderValue = false;
            this.ascendingVisitNumberValue = false;

            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
        },
        downloadListCustomer() {
            if (this.listCustomerData.length === 0) {
                this.$swal(this.$t('dashboard.NoData'), '', 'warning');
            } else {
                this.$swal({
                    title: this.$t('CustomerResponse.CM99999'),
                    allowOutsideClick: true,
                });
                this.$swal.showLoading();
                downloadListCustomerAPI
                    .post(
                        this.searchValue,
                        this.sortAttribute,
                        this.ascending,
                        this.genArr.toString(),
                        this.ageArr.toString(),
                        this.customerTypeArr.toString(),
                        localStorage.lang
                    )
                    .then(response => {
                        this.$swal.close();
                        const url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: 'application/vnd.ms-excel',
                            })
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'listCustomer.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        },
        showAddCustomerTypeModal() {
            listCustomerTypeAPI.get().then(response => {
                let listCustomerType = response.data['listCustomerTypeResponse'];

                for (let i = 0; i < listCustomerType.length; i++) {
                    listCustomerType[i].customerTypeEditMode = false;
                    listCustomerType[i].customerTypeEditing = false;
                    this.customerTypeValue = '';
                }
                this.listCustomerType = listCustomerType;
                this.$modal.show('customer-type-modal');
            });
        },

        showCustomerModal() {
            this.editingCustomer = false;
            this.$modal.show('customer-modal');
        },
        showEditCustomerModal(customerID) {
            const self = this;
            getCustomerDetailV2.get(customerID).then(response => {
                const responseFromAPI = response.data;
                if (responseFromAPI.customerType !== '') {
                    self.customerTypeTitleInModal = responseFromAPI.customerTypeName;
                    self.customerTypeInModal = responseFromAPI.customerType;
                } else {
                    self.customerTypeTitleInModa = this.$t('Customers.TypeCustomerType');
                }

                self.customerNameInModal = responseFromAPI.name;
                self.customerAgeInModal = responseFromAPI.age;
                self.rangeTime = responseFromAPI.birthDate;
                self.rangeTimeString = responseFromAPI.birthDate;
                self.customerTelephoneInModal = responseFromAPI.telephone;
                self.customerImageInModal = responseFromAPI.image;
                self.customerIDInModal = customerID;

                if (responseFromAPI.gender === 'male') {
                    self.genderTitleInModal = self.$t('dashboard.Male');
                } else {
                    self.genderTitleInModal = self.$t('dashboard.Female');
                }
                self.customerGenderInModal = responseFromAPI.gender;
                self.editingCustomer = true;
                self.$modal.show('customer-modal');
            });
        },
        hideCustomerModal() {
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                this.limit
            );
            this.$modal.hide('customer-modal');
        },
        turnFilter() {
            this.filterMode = this.filterMode !== true;
        },
        chooseAgeFilter() {
            this.$refs['wrapAgeFilterOption'].style.position = 'absolute';
            this.$refs['wrapAgeFilterOption'].style.display = 'block';
            this.$refs['wrapAgeFilterOption'].style.visibility = 'visible';
            this.ageRangeAvailable = true;
        },
        handleClickOutsideAgeFilter(index) {
            if (index) {
                this.$refs['wrapAgeFilterOption'].style.display = 'none';
                this.$refs['wrapAgeFilterOption'].style.visibility = 'hidden';
                this.ageRangeAvailable = false;
            }
        },

        chooseGenderFilter() {
            this.$refs['wrapGenderFilterOption'].style.position = 'absolute';
            this.$refs['wrapGenderFilterOption'].style.display = 'block';
            this.$refs['wrapGenderFilterOption'].style.visibility = 'visible';
            this.genderAvailable = true;
        },
        handleClickOutsideGenderFilter(index) {
            if (index) {
                this.$refs['wrapGenderFilterOption'].style.display = 'none';
                this.$refs['wrapGenderFilterOption'].style.visibility = 'hidden';
                this.genderAvailable = false;
            }
        },

        chooseCustomerTypeFilter() {
            this.$refs['wrapCustomerTypeFilterOption'].style.position = 'absolute';
            this.$refs['wrapCustomerTypeFilterOption'].style.display = 'block';
            this.$refs['wrapCustomerTypeFilterOption'].style.visibility = 'visible';
            this.segmentAvailable = true;
        },
        handleClickOutsideCustomerTypeFilter(index) {
            if (index) {
                this.$refs['wrapCustomerTypeFilterOption'].style.display = 'none';
                this.$refs['wrapCustomerTypeFilterOption'].style.visibility = 'hidden';
                this.segmentAvailable = false;
            }
        },

        chooseLimitFilter() {
            this.$refs['wrapLimitFilterOption'].style.position = 'absolute';
            this.$refs['wrapLimitFilterOption'].style.display = 'block';
            this.$refs['wrapLimitFilterOption'].style.visibility = 'visible';
        },
        handleClickOutsideLimitFilter() {
            this.$refs['wrapLimitFilterOption'].style.display = 'none';
            this.$refs['wrapLimitFilterOption'].style.visibility = 'hidden';
        },

        chooseGenderOptionInModal() {
            this.$refs['wrapGenderOption'].style.position = 'absolute';
            this.$refs['wrapGenderOption'].style.display = 'block';
            this.$refs['wrapGenderOption'].style.visibility = 'visible';
        },
        handleClickOutSideGenderInModal() {
            this.$refs['wrapGenderOption'].style.display = 'none';
            this.$refs['wrapGenderOption'].style.visibility = 'hidden';
        },

        changeGenderInModal(value, valueInVietNamLanguage) {
            var self = this;
            setTimeout(() => {
                self.handleClickOutSideGenderInModal();
            }, 100);
            this.customerGenderInModal = value.toLowerCase();
            this.genderTitleInModal = this.$t('dashboard.' + value);
        },

        chooseCustomerTypeOptionInModal() {
            this.$refs['wrapCustomerTypeOption'].style.position = 'absolute';
            this.$refs['wrapCustomerTypeOption'].style.display = 'block';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'visible';
        },
        handleClickOutSideCustomerTypeInModal() {
            this.$refs['wrapCustomerTypeOption'].style.display = 'none';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'hidden';
        },

        changeCustomerTypeInModal(value, customerTypeID) {
            var self = this;
            setTimeout(() => {
                self.handleClickOutSideCustomerTypeInModal();
            }, 100);
            this.customerTypeInModal = customerTypeID;
            this.customerTypeTitleInModal = value;
        },

        beforeCloseCustomerModal() {
            this.customerTypeTitleInModal = this.$t('Customers.TypeCustomerType');
            this.customerTypeInModal = '';
            this.genderTitleInModal = this.$t('Customers.TypeGender');
            this.customerGenderInModal = '';
            this.customerNameInModal = '';
            this.customerAgeInModal = '';
            this.customerTelephoneInModal = '';
            this.customerImageInModal = '';
            this.imageFile = '';
            this.rangeTimeString = '01/01/1996';
            this.rangeTime = '01/01/1996';
        },
        beforeCloseCustomerTypeModal() {
            this.customerTypeValue = '';
        },

        customerCheckFunction(customerID, input) {
            if (input === false) {
                const index = this.listCustomerIDChecked.indexOf(customerID);
                if (index !== -1) {
                    this.listCustomerIDChecked.splice(index, 1);
                }
            } else {
                this.listCustomerIDChecked.push(customerID);
            }
        },
        changePage(number) {
            if (number === -1 && this.page !== 1) {
                this.page = this.page - 1;
                this.callGetListCustomer(
                    this.searchValue,
                    this.sortAttribute,
                    this.ascending,
                    this.genArr.toString(),
                    this.ageArr.toString(),
                    this.customerTypeArr.toString(),
                    this.page,
                    this.limit
                );
            }
            // back to the first page
            if (number === 0 && parseInt(this.page) > 1) {
                this.page = 1;
                this.callGetListCustomer(
                    this.searchValue,
                    this.sortAttribute,
                    this.ascending,
                    this.genArr.toString(),
                    this.ageArr.toString(),
                    this.customerTypeArr.toString(),
                    1,
                    this.limit
                );
            }
            if (number === 1) {
                this.page = this.page < this.totalPage ? this.page + 1 : this.page;
                this.callGetListCustomer(
                    this.searchValue,
                    this.sortAttribute,
                    this.ascending,
                    this.genArr.toString(),
                    this.ageArr.toString(),
                    this.customerTypeArr.toString(),
                    this.page,
                    this.limit
                );
            }
            // through to last page
            if (number === parseInt(this.totalPage)) {
                this.page = parseInt(this.totalPage);
                this.callGetListCustomer(
                    this.searchValue,
                    this.sortAttribute,
                    this.ascending,
                    this.genArr.toString(),
                    this.ageArr.toString(),
                    this.customerTypeArr.toString(),
                    parseInt(this.page),
                    this.limit
                );
            }
        },
        changeEditCustomerMode(customerID, input) {
            if (input === false) {
                for (var i = 0; i < this.listCustomerData.length; i++) {
                    if (this.listCustomerData[i].id === customerID) {
                        this.listCustomerData[i].customerEdit = false;
                    }
                }
            } else {
                for (var i = 0; i < this.listCustomerData.length; i++) {
                    if (this.listCustomerData[i].id === customerID) {
                        this.listCustomerData[i].customerEdit = true;
                    }
                }
            }
        },
        changeEditCustomerTypeMode(customerTypeID, input) {
            if (input === false) {
                for (var i = 0; i < this.listCustomerType.length; i++) {
                    if (this.listCustomerType[i].id === customerTypeID) {
                        this.listCustomerType[i].customerTypeEditMode = false;
                        this.listCustomerType[i].customerTypeEditing = false;
                        this.customerTypeValue = '';
                    }
                }
            } else {
                for (var i = 0; i < this.listCustomerType.length; i++) {
                    if (this.listCustomerType[i].id === customerTypeID) {
                        this.listCustomerType[i].customerTypeEditMode = true;
                    } else {
                        this.listCustomerType[i].customerTypeEditMode = false;
                        this.listCustomerType[i].customerTypeEditing = false;
                    }
                }
            }
        },
        changeLimit(number, refName) {
            this.limit = number;
            var listLimit = ['limitFive', 'limitTen', 'limitFifteen', 'limitTwenty', 'customLimit'];
            for (var index = 0; index < listLimit.length; index++) {
                if (listLimit[index] !== refName) {
                    this.$refs[listLimit[index]].classList.value = ['Option'];
                } else {
                    this.$refs[listLimit[index]].classList.value = ['OptionActive'];
                }
            }
        },
        callGetListCustomer(
            searchValue,
            sortAttribute,
            ascending,
            genderFilter,
            ageFilter,
            customerTypeFilter,
            page,
            limit
        ) {
            getListCustomer
                .get(searchValue, sortAttribute, ascending, genderFilter, ageFilter, customerTypeFilter, page, limit)
                .then(response => {
                    var listCustomerData = response.data.listResponse;
                    if (listCustomerData.length === 0) {
                        this.$notify({
                            timeout: 2500,
                            message: this.$t('dashboard.NoData'),
                            icon: 'add_alert',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'primary',
                        });
                    }

                    for (let i = 0; i < listCustomerData.length; i++) {
                        if (localStorage.lang === 'vn') {
                            if (listCustomerData[i].gender === 'male') {
                                listCustomerData[i].gender = 'Nam';
                            } else {
                                listCustomerData[i].gender = 'Nữ';
                            }
                        }

                        for (let index = 0; index < this.listCustomerIDChecked.length; index++) {
                            if (listCustomerData[i].id === this.listCustomerIDChecked[index]) {
                                listCustomerData[i].customerIDChecked = true;
                            }
                        }
                        listCustomerData[i].customerEdit = false;
                    }
                    this.listCustomerData = listCustomerData;
                    this.totalPage = response.data.totalPage;
                    this.handleClickOutsideAgeFilter();
                    this.handleClickOutsideGenderFilter();
                    this.handleClickOutsideCustomerTypeFilter();
                    this.handleClickOutsideLimitFilter();
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.imageFile = file;
            this.customerImageInModal = URL.createObjectURL(file);
        },
        chooseTime() {
            this.showRangePicker = true;
        },
        // filter methods
        handleClickOutsideRangePicker(event) {
            if (this.showRangePicker === true && !ListDatepickerClass.includes(event.srcElement._prevClass)) {
                if (navigator.userAgent.indexOf('Firefox') === -1) {
                    if (event.path.length === 15) {
                        if (event.path[9]._prevClass !== 'mx-datepicker-content') {
                            this.showRangePicker = false;
                        }
                    } else {
                        this.showRangePicker = false;
                    }
                } else {
                    if (
                        event.target.parentElement._prevClass !== 'cell' &&
                        event.target.parentElement._prevClass !== 'cell active' &&
                        event.target.parentElement._prevClass !== 'cell today active'
                    ) {
                        this.showRangePicker = false;
                    }
                }
            }
        },
        callDeleteCustomerAPI(customerID) {
            this.$swal({
                title: this.$t('Customers.DeleteCustomerText'),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: this.$t('Customers.AcceptToDelete'),
                cancelButtonText: this.$t('Customers.Cancel'),
            }).then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.value) {
                    deleteCustomer
                        .post(customerID)
                        .then(response => {
                            this.$swal(
                                this.$t(`CustomerResponse.${response.data.message.replace('.', '')}`),
                                '',
                                'success'
                            ).then(result => {
                                this.hideCustomerModal();
                            });
                        })
                        .catch(error => {
                            this.$swal.close();
                            this.$swal(
                                this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                        });
                }
            });
        },
        validateCustomerNameData(name) {
            if (name.length >= 1 && name.replace(/\s/g, '') === '') {
                this.validateMessage['customerName'] = this.$t('CustomerResponse.CM00002');
            } else {
                this.validateMessage['customerName'] = '';
            }
        },
        validatePhoneCustomer(phone) {
            if (Boolean(phone) && phone.length !== 10) {
                this.validateMessage['customerPhone'] = this.$t('CustomerResponse.CM00032');
            } else if (phone !== '' && !/^-?\d+$/.test(phone)) {
                this.validateMessage['customerPhone'] = this.$t('CustomerResponse.CM00031');
            } else {
                this.validateMessage['customerPhone'] = '';
            }
        },
        callAPIAddCustomer() {
            this.customerAgeInModal = parseInt(this.customerAgeInModal);
            if (Boolean(this.customerTelephoneInModal)) {
                this.customerTelephoneInModal = this.customerTelephoneInModal.replaceAll(/\s/g, '');
            }
            // remove validate customer name
            // change into using watch event to validate data
            this.$swal({
                title: this.$t('CustomerResponse.CM99999'),
                allowOutsideClick: true,
            });
            this.$swal.showLoading();
            let bodyFormData = new FormData();
            bodyFormData.append('birthDate', this.rangeTimeString);
            bodyFormData.append('gender', this.customerGenderInModal);
            bodyFormData.append('image', this.imageFile);
            bodyFormData.append('name', this.customerNameInModal.replace(/  +/g, ' '));
            bodyFormData.append('customerType', this.customerTypeInModal);
            if (this.customerTelephoneInModal !== null && Boolean(this.customerTelephoneInModal) === true) {
                bodyFormData.append('telephone', this.customerTelephoneInModal);
            }

            if (!this.editingCustomer) {
                addCustomer
                    .post(bodyFormData)
                    .then(response => {
                        this.$swal.close();
                        this.$swal(this.$t('CustomerResponse.CM00020'), '', 'success').then(this.hideCustomerModal());
                    })
                    .catch(error => {
                        if (error.response === undefined) {
                            this.$swal.close();
                            this.$swal(this.$t('CustomerResponse.CM99998'), '', 'warning');
                        } else {
                            this.$swal.close();
                            this.$swal(
                                this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                        }
                    });
            } else {
                updateCustomerAPI
                    .put(this.customerIDInModal, bodyFormData)
                    .then(response => {
                        this.$swal.close();
                        this.$swal(
                            this.$t(`CustomerResponse.${response.data.message.replace('.', '')}`),
                            '',
                            'success'
                        ).then(_ => {
                            this.hideCustomerModal();
                            this.callGetListCustomer(
                                this.searchValue,
                                this.sortAttribute,
                                this.ascending,
                                this.genArr.toString(),
                                this.ageArr.toString(),
                                this.customerTypeArr.toString(),
                                this.page,
                                this.limit
                            );
                        });
                    })
                    .catch(error => {
                        if (error.response === undefined) {
                            this.$swal.close();
                            this.$swal(this.$t('CustomerResponse.CM99998'), '', 'warning');
                        } else {
                            this.$swal.close();
                            this.$swal(
                                this.$t(`CustomerResponse.${error.response.data.message.replace('.', '')}`),
                                '',
                                'warning'
                            );
                            this.callGetListCustomer(
                                this.searchValue,
                                this.sortAttribute,
                                this.ascending,
                                this.genArr.toString(),
                                this.ageArr.toString(),
                                this.customerTypeArr.toString(),
                                this.page,
                                this.limit
                            );
                        }
                    });
            }
        },
    },
    mounted() {
        this.langData = localStorage.lang;
        let isCallingCustomerType = false;
        if (Boolean(localStorage.getItem('currentCustomerData'))) {
            const data = JSON.parse(localStorage.getItem('currentCustomerData'));
            this.ageArr = Boolean(data['ageFilter']) ? data['ageFilter'].split(',') : [];
            if (this.ageArr !== []) {
                let ageArrData = data['ageFilter'].split(',');
                for (const age of this.ageOptions) {
                    ageArrData.forEach(el => {
                        if (age.text === el) {
                            age.isChecked = true;
                        }
                    });
                }
            }
            this.genArr = Boolean(data['genderFilter']) ? data['genderFilter'].split(',') : [];
            if (this.genArr !== []) {
                let genderArrData = data['genderFilter'].split(',');
                for (const gender of this.genderOptions) {
                    genderArrData.forEach(el => {
                        if (gender.gen === el) {
                            gender.isChecked = true;
                        }
                    });
                }
            }
            this.customerTypeArr = Boolean(data['customerTypeFilter']) ? data['customerTypeFilter'].split(',') : [];
            if (this.customerTypeArr.length > 0) {
                let customerTypeArrData = this.customerTypeArr;
                isCallingCustomerType = true;
                listCustomerTypeAPI.get().then(response => {
                    let listCustomerType = response.data['listCustomerTypeResponse'];
                    this.listCustomerTypeInAdd = listCustomerType.filter(s => s.id !== '');
                    for (const customerTypeData of listCustomerType) {
                        if (localStorage?.lang === 'en' && customerTypeData?.name === 'Chưa có phân khúc') {
                            customerTypeData['name'] = 'No Segment';
                        }
                    }
                    this.listCustomerSegmentFilter = listCustomerType;
                    for (const customerSegment of this.listCustomerSegmentFilter) {
                        customerTypeArrData.forEach(el => {
                            if (customerSegment?.name === el) {
                                customerSegment.isChecked = !customerSegment.isChecked;
                            }
                        });
                    }
                });
            }
            this.searchValue = data['searchValue'];
            this.callGetListCustomer(
                data.searchValue,
                data.sortAttribute,
                data.ascending,
                data.genderFilter,
                data.ageFilter,
                data.customerTypeFilter,
                data.page,
                data.limit
            );
            this.page = data.page;
            this.limit = data.limit;

            const self = this;
            getCustomerDetailV2.get(data['cusID']).then(response => {
                const responseFromAPI = response.data;

                if (responseFromAPI.customerType !== '') {
                    self.customerTypeTitleInModal = responseFromAPI.customerTypeName;
                    self.customerTypeInModal = responseFromAPI.customerType;
                } else {
                    self.customerTypeTitleInModa = this.$t('Customers.TypeCustomerType');
                }
                self.customerNameInModal = responseFromAPI.name;
                self.customerAgeInModal = responseFromAPI.age;
                self.rangeTime = responseFromAPI.birthDate;
                self.rangeTimeString = responseFromAPI.birthDate;
                self.customerTelephoneInModal = Boolean(responseFromAPI.telephone) ? responseFromAPI.telephone : '';
                self.customerImageInModal = responseFromAPI.image;
                self.customerIDInModal = data['cusID'];

                if (responseFromAPI.gender === 'male') {
                    self.genderTitleInModal = self.$t('dashboard.Male');
                } else {
                    self.genderTitleInModal = self.$t('dashboard.Female');
                }
                self.customerGenderInModal = responseFromAPI.gender;
                self.editingCustomer = true;
                self.$modal.show('customer-modal');
                localStorage.removeItem('currentCustomerData');
            });
        } else {
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genderFilter,
                this.ageFilter,
                this.customerTypeFilter,
                this.page,
                this.limit
            );
        }
        if (isCallingCustomerType === false) {
            listCustomerTypeAPI.get().then(response => {
                let listCustomerType = response.data['listCustomerTypeResponse'];

                for (let i = 0; i < listCustomerType.length; i++) {
                    listCustomerType[i].customerTypeEditMode = false;
                    listCustomerType[i].customerTypeEditing = false;
                    this.customerTypeValue = '';
                }
                this.listCustomerTypeInAdd = listCustomerType.filter(s => s.id !== '');
                for (const customerTypeData of listCustomerType) {
                    if (localStorage?.lang === 'en' && customerTypeData?.name === 'Chưa có phân khúc') {
                        customerTypeData['name'] = 'No Segment';
                    }
                }
                this.listCustomerType = listCustomerType;
                this.listCustomerSegmentFilter = listCustomerType;
            });
        }
    },
    watch: {
        limit(val) {
            this.page = 1;
            this.callGetListCustomer(
                this.searchValue,
                this.sortAttribute,
                this.ascending,
                this.genArr.toString(),
                this.ageArr.toString(),
                this.customerTypeArr.toString(),
                this.page,
                val
            );
        },
        customerNameInModal(value) {
            this.customerNameInModal = value;
            this.validateCustomerNameData(value);
        },
        customerTelephoneInModal(value) {
            this.customerTelephoneInModal = value;
            this.validatePhoneCustomer(value);
        },
        rangeTime(val) {
            if (val === null) {
                this.rangeTimeString = '01/01/1996';
            } else {
                if (
                    moment(val)
                        .format('DD/MM/YYYY')
                        .toString() !==
                    moment()
                        .format('DD/MM/YYYY')
                        .toString()
                ) {
                    this.rangeTimeString = moment(val)
                        .format('DD/MM/YYYY')
                        .toString();
                } else {
                    this.rangeTimeString = '01/01/1996';
                }
            }
        },
    },
    data() {
        var self = this;
        return {
            editingCustomer: false,
            listCustomerIDChecked: [],
            listCustomerType: [],
            listCustomerTypeInAdd: [],
            customerIDChecked: '',
            listCustomerData: [],
            filterMode: false,
            searchValue: '',
            sortAttribute: 'name',
            ascending: true,
            genderFilter: '',
            ageFilter: '',
            customerTypeFilter: '',
            page: 1,
            limit: 5,
            totalPage: 1,
            showRangePicker: false,
            dateLanguage: '',

            ageFilterResult: self.$t('Customers.AgeRange'),
            genderFilterResult: self.$t('Customers.TypeGender'),
            customerTypeResult: self.$t('Customers.TypeCustomerType'),
            customerTypeDisplay: '',

            ascendingNameValue: true,
            ascendingAgeValue: false,
            ascendingGenderValue: false,
            ascendingVisitNumberValue: false,
            ascendingSegmentValue: true,

            customerIDInModal: '',
            customerImageInModal: '',
            rangeTime: moment('01/01/1996').format('DD/MM/YYYY'),
            rangeTimeString: moment('01/01/1996').format('DD/MM/YYYY'),
            customerNameInModal: '',
            customerTelephoneInModal: '',
            customerAgeInModal: '',
            customerGenderInModal: '',
            customerTypeInModal: '',
            imageFile: '',

            genderTitleInModal: self.$t('Customers.TypeGender'),
            customerTypeTitleInModal: self.$t('Customers.TypeCustomerType'),

            customerTypeValue: '',
            addingCustomerType: false,
            customerTypeAddingValue: '',

            ageRangeAvailable: false,
            genderAvailable: false,
            segmentAvailable: false,
            validateMessage: [],
            ageOptions: [
                { text: '10 - 16', isChecked: false },
                { text: '17 - 24', isChecked: false },
                { text: '25 - 30', isChecked: false },
                { text: '31 - 34', isChecked: false },
                { text: '35 - 45', isChecked: false },
                { text: '46 - 60', isChecked: false },
                { text: '60 - 100', isChecked: false },
            ],
            ageArr: [],
            genderOptions: [
                { gen: 'male', isChecked: false },
                { gen: 'female', isChecked: false },
            ],
            genArr: [],
            customerTypeArr: [],
            customerBehaviorArr: [],
            back: false,
            langData: '',
            listCustomerSegmentFilter: [],
        };
    },
};
</script>

<style lang="scss">
.HeaderGuess {
    display: flex;
    justify-content: space-between;

    .HeaderGuess-title {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: #1f1f1f;
    }

    .HeaderGuess-actions {
        display: flex;

        .btn-show-type1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #276eff;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 268px;
            height: 56px;
            padding: 16px 24px;
            border: 1px solid #276eff;
            box-sizing: border-box;
            border-radius: 8px;
            margin-right: 16px;
        }

        .header-img {
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 100px;
                margin-bottom: 16px;
                height: 100px;
            }

            .InputCustom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 24px;
                padding: 16px 16px;
                height: 40px;
                border: 1px dashed #276eff;
                box-sizing: border-box;
                border-radius: 8px;
                color: #276eff;
                font-family: Roboto;
                width: auto;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                position: relative;

                input {
                    width: 148px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                }
            }
        }

        .sectionPogup {
            width: 100%;
            padding: 24px;

            .wrapSelectNamePhone {
                display: flex;
                justify-content: space-between;

                .SelectName {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 12px);
                }

                .SelectPhone {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 12px);

                    .mx-datepicker {
                        height: 48px;
                        border: 1px solid rgba(86, 103, 137, 0.26);
                        box-sizing: border-box;
                        border-radius: 8px;
                        width: 100% !important;
                        display: flex;
                        align-items: center;

                        .mx-input-wrapper {
                            height: 100% !important;
                            width: 100% !important;
                            border-radius: 8px;

                            .mx-input {
                                height: 100% !important;
                                width: 100% !important;
                                border-radius: 8px;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .BottomPogup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 24px;

            .wrapaddCSV {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-addCSV {
                    border: 1px dashed #276eff;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 9px 12px;
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #276eff;
                    position: relative;
                    cursor: pointer;

                    input {
                        width: 176px;
                        height: 40px;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        cursor: pointer;
                        position: absolute;
                    }
                }

                .dowload {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .material-icons {
                        cursor: pointer;
                        color: #8c8c8c;
                    }

                    .wrapDownload {
                        position: relative;

                        .linkDownload {
                            z-index: 9999;
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            opacity: 0;
                        }
                    }
                }
            }

            .wrapSaveCancel {
                display: flex;
                justify-content: center;
                align-items: center;

                .Btn-Close {
                    padding: 6px 16px;
                    width: 104px;
                    height: 42px;
                    background: #f0f0f0;
                    border-radius: 8px;
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .Btn-Save {
                    padding: 6px 16px;
                    width: 104px;
                    height: 42px;
                    background: #276eff;
                    border-radius: 8px;
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }

        .btn-show {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #276eff;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 56px;
            padding: 16px 24px;
            border: 1px solid #276eff;
            box-sizing: border-box;
            border-radius: 8px;
            position: relative;

            input {
                width: 178px;
                height: 56px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }

        .WrapModal {
            padding: 24px;

            .HeaderTitle {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: hsl(0, 0%, 12%);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .material-icons {
                    cursor: pointer;
                }
            }

            .wrapContent {
                .wrapheader {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    background-color: #064b6c;
                    color: #fff;

                    .nameSegment {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        width: 33%;
                    }
                }

                .btn-search {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 12px 16px;
                    overflow: hidden;
                    width: 204px;
                    height: 40px;
                    border: 1px solid #8c8c8c;
                    box-sizing: border-box;
                    border-radius: 8px;
                    color: #bfbfbf;
                    cursor: pointer;

                    .material-icons {
                        color: #8c8c8c;
                    }

                    input {
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1f1f1f;
                        width: 100%;
                        height: 38px;
                        border: 0px solid #fff;

                        &::placeholder {
                            color: #bfbfbf;
                        }
                    }
                }

                .WrapOptionCustom {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    height: 312px;
                    overflow: auto;
                    z-index: 9999;

                    .OptionCustom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 16px;
                        border-top: 1px solid #d9d9d9;

                        .EditIcon {
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            width: 33%;
                            text-align: center;
                        }

                        .TypeCustomer {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            width: 33%;
                            text-align: center;
                        }
                    }

                    .OptionCustom:first-child {
                        border-top: none;
                    }

                    .EditOption {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        font-family: 'Roboto';
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;

                        input {
                            border: 1px solid #8c8c8c;
                            box-sizing: border-box;
                            border-radius: 8px;
                            padding: 8px 16px;
                        }

                        .material-icons {
                            color: #107c10;
                            margin-left: 16px;
                        }
                    }

                    .positionOption {
                        position: absolute;
                        bottom: 64px;
                        left: 24px;
                    }

                    .bg-White {
                        background-color: #fff;
                    }
                }

                .OptionEnd {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    color: #276eff;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}

.WrapSection {
    padding: 24px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 32px;

    .WrapBtn {
        display: flex;

        .btn-search {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            width: 256px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #bfbfbf;
            cursor: pointer;

            .material-icons {
                color: #8c8c8c;
            }

            input {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1f1f1f;
                width: 100%;
                height: 54px;
                border: 0px solid #fff;

                &::placeholder {
                    color: #bfbfbf;
                }
            }
        }

        .btn-filter {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #5a6170;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #8c8c8c;
            cursor: pointer;

            .material-icons {
                color: #8c8c8c;
            }
        }

        .btn-export-csv {
            height: 80%;
            padding: 10px;
            font-size: 14px;
            background-color: #4476d9;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ml-auto {
            margin-left: auto;
        }

        .btn-delete {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            box-sizing: border-box;
            border-radius: 8px;
            color: #ff4343;
            cursor: pointer;
        }
    }

    .SelectFilter {
        width: 100%;
        margin-top: 24px;
        display: flex;
        cursor: pointer;
    }

    .WrapTable {
        margin-top: 24px;

        table {
            border-spacing: 0 !important;
            width: 100%;
        }

        .tr-head {
            height: 80px;
            background-color: #064b6c;
            color: #fff;

            th {
                width: 200px;

                .material-icons {
                    vertical-align: middle;
                }
            }

            th:first-child {
                width: 48px !important;
            }

            th:nth-child(2) {
                text-align: left;
                padding-left: 14px;
            }
        }

        td {
            text-align: center;
            height: 64px;
            padding: 8px 12px;
            font-family: 'Roboto';
            font-size: 14px;
            color: #272833;
            font-weight: 400;

            img {
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }
        }

        .border-bottom {
            border-bottom: solid 1px #f0f0f0;
        }

        .customer-name-width {
            width: 200px;
        }

        .border-left {
            border-left: solid 1px #f0f0f0;
        }

        .border-right {
            border-right: solid 1px #f0f0f0;
        }

        td:first-child {
            width: 48px !important;
        }

        td:nth-child(2) {
            max-width: 200px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        td:last-child {
            .material-icons {
                cursor: pointer;
            }
        }

        td:nth-child(6) {
            max-width: 64px !important;
        }

        .pagination {
            display: inline-block;
            border-radius: 5px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .wrapSelectPagination {
                display: flex;
                align-items: center;
                position: relative;

                .seeTable {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'Roboto';
                }

                .SelectPagination {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 60px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'Roboto';
                    padding: 8px 8px;
                    cursor: pointer;
                    border-radius: 8px;
                    margin-left: 16px;

                    // &:hover {
                    //     background-color: #F6F9FF;
                    //     .wrapOptionPagination {
                    //         display: block;
                    //     }
                    // }
                    .wrapOptionPagination {
                        position: absolute;
                        display: none;
                        padding: 8px;
                        width: 60px;
                        background: #ffffff;
                        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
                        border-radius: 10px;
                        top: 40px;
                        right: 0;
                        z-index: 9;

                        .Option {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: 36px;
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                        }

                        .OptionActive {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: auto;
                            font-family: 'Roboto';
                            background-color: #edf3ff;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .pagination a {
            color: black;
            float: left;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'Roboto';
            padding: 12px 16px;
            text-decoration: none;
        }

        .pagination a.active {
            color: #2b4bf2;
            border-radius: 5px;
        }

        .pagination a:hover:not(.active) {
            background-color: #f6f9ff;
            border-radius: 5px;
            color: #fff;
        }
    }
}

.selectCustom {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    width: 212px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5a6170;
    border: 1px solid #8c8c8c;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    .wrapOption {
        position: absolute;
        display: none;
        padding: 8px;
        z-index: 9999;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 40px;
        left: -2px;
        max-height: 312px;
        overflow: auto;

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;

            &:hover {
                background: #edf3ff;
            }
        }

        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }

    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
    .Texoverflow {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: '...';
    }
}

.CloserFilter {
    margin-left: 16px;
    height: 40;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LabelInput {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #2b4bf2;
}

.InputCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;

    &::placeholder {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8c8c8c;
    }
}

.SelectCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    position: relative;

    .wrapOption {
        position: absolute;
        z-index: 9999;
        display: none;
        padding: 8px;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 50px;
        left: -2px;

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }

        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }

    .wrapOptionSegment {
        position: absolute;
        display: none;
        padding: 8px;
        width: fit-content;
        height: 124px;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 52px;
        left: -2px;
        z-index: 9999;
        max-height: 312px;
        overflow: auto;

        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }

        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }

    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
}

.mt-8px {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.width100 {
    width: 100% !important;
}

.ColorEdit {
    color: #ffb900;
}

.ColorDelete {
    color: #ff4343;
}

.z-index9999 {
    z-index: 9999;
}

.ActiveButton {
    border: 1px solid #276eff !important;
    color: #276eff !important;

    .material-icons {
        color: #276eff !important;
    }
}

.w-300 {
    width: 300px;
}
</style>
