<template>
    <div class="wrapPage">
        <div class="Title-Customer-Report">
            {{ $t('menu.CustomerReport') }}
        </div>
        <div
            class="Choosetime-Customer mt-24"
            style="cursor: pointer;"
            @click="showDateRange()"
            v-click-outside="clickOutsideTypeOfDate"
        >
            <b>{{ typeOfDateDisplay }}</b>
            <span class="material-icons ml-8">calendar_today</span>
            <div class="wrapOption" ref="dateRangeOption" style="cursor: pointer;">
                <div
                    class="Option"
                    v-for="(range, index) in dateSelect"
                    :key="index"
                    @click="changeDateType(range.type)"
                >
                    <b>{{ objTypeOfDate[range.type] }}</b>
                </div>
            </div>
        </div>
        <div class="wrapContent mt-40">
            <div class="Content-Left">
                <div class="Behaviour">
                    <div class="TitleChart">
                        {{ $t('CustomerReport.BehaviorTitle') }}
                        <div class="tooltip ml-8">
                            <span class="material-icons" style="color: #BFBFBF; cursor: default">help</span>
                            <span class="tooltiptext" style="color: #000000; size: 12px">{{
                                $t('CustomerReport.BehaviorTooltip')
                            }}</span>
                        </div>
                    </div>
                    <ChartBehaviour
                        :x-value="listCount"
                        :y-value="listDate"
                        :type-of-customer="listType"
                        :type-of-customer-value="listTypeValue"
                        :type-of-customer-percent="listTypePercent"
                        :type-of-date="typeOfDateDisplay"
                    />
                </div>
                <div class="TableCustomer mt-40">
                    <div class="headerTable">
                        <span class="titleTableCustomer">
                            {{ $t('Customers.ListCustomer') }}
                        </span>
                        <div class="wrapButton">
                            <div class="BTN-Download mr-8">
                                <span
                                    class="material-icons"
                                    style="cursor:pointer;"
                                    @click="downloadListCustomerByDateRange()"
                                >
                                    download
                                </span>
                            </div>
                            <div
                                class="BTN-ChooseOption"
                                style="cursor: pointer"
                                @click="showTypeOfCustomerListCustomer()"
                                v-click-outside="clickOutsideListCustomer"
                            >
                                {{ typeCustomerDisplayInListCustomer }}
                                <span class="material-icons ml-8">arrow_drop_down</span>
                                <div class="wrapOption" style="cursor: pointer" ref="typeCustomerOpt">
                                    <div
                                        class="Option"
                                        v-for="(type, index) in typeOfCustomer"
                                        :key="index"
                                        @click="changeTypeInListCustomer(Object.keys(type)[1].toString())"
                                    >
                                        {{ type[Object.keys(type)[1].toString()] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table id="listCustomers">
                        <tr class="tr-head">
                            <th style="color: white">{{ $t('Customers.FullName') }}</th>
                            <th style="color: white">{{ $t('Customers.Gender') }}</th>
                            <th style="color: white">{{ $t('Customers.Phone') }}</th>
                            <th style="color: white">{{ $t('Customers.Segment') }}</th>
                            <th style="color: white">{{ $t('Customers.TotalNumberOfDaysNotVisited') }}</th>
                            <th style="color: white">{{ $t('Customers.NumberOfVisits') }}</th>
                        </tr>
                        <tr v-for="customer in listCustomerInRange" :key="customer.id">
                            <td style="cursor: pointer;" @click="showModal(customer.id)">
                                <img :src="customer.image" alt="Italian Trulli" @error="handleErrorLoadImage" />
                                {{ customer.name }}
                                <md-tooltip md-direction="top">{{ customer.name }}</md-tooltip>
                            </td>
                            <td v-if="langSelect === 'vn'">{{ customer.gender === 'male' ? 'Nam' : 'Nữ' }}</td>
                            <td v-else>{{ customer.gender === 'male' ? 'Male' : 'Female' }}</td>

                            <td>{{ customer.telephone }}</td>
                            <td>{{ customer.customerType }}</td>
                            <td>
                                {{ customer['totalDaysSinceLastVisit'] + (langSelect === 'vn' ? ' ngày' : ' day') }}
                            </td>
                            <td>{{ customer['visitNumber'] }}</td>
                        </tr>
                    </table>

                    <div class="pagination mt-16">
                        <a style="cursor: pointer;" v-show="page !== 1" @click="changePage(0)">
                            <span class="material-icons">first_page</span>
                        </a>
                        <a style="cursor: pointer;" v-show="page !== 1" @click="changePage(-1)"
                            ><span class="material-icons">navigate_before</span>
                        </a>
                        <a
                            style="cursor: pointer;"
                            @click="changePage(-1)"
                            v-if="(page !== 1 || page === parseInt(totalPage)) && parseInt(totalPage) > 1"
                            >{{ page - 1 }}</a
                        >
                        <a style="cursor: pointer;" class="active">{{ page }}</a>

                        <a
                            style="cursor: pointer;"
                            @click="changePage(1)"
                            v-if="page !== parseInt(totalPage) && page < parseInt(totalPage)"
                        >
                            {{ page + 1 }}
                        </a>
                        <a style="cursor: pointer;" v-show="page !== parseInt(totalPage)" @click="changePage(1)">
                            <span class="material-icons">navigate_next</span>
                        </a>
                        <a
                            style="cursor: pointer;"
                            v-show="page !== parseInt(totalPage)"
                            @click="changePage(parseInt(totalPage))"
                        >
                            <span class="material-icons">last_page</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="Content-Right pd-24">
                <div
                    class="NeedAttention"
                    style="cursor: pointer"
                    @click="showTypeOfCustomer()"
                    v-click-outside="clickOutsideSelectTypeOfCustomer"
                >
                    {{ typeCustomerDisplay }}
                    <span class="material-icons ml-8">arrow_drop_down</span>
                    <div class="wrapOption" ref="typeOfCustomerOption" style="cursor: pointer">
                        <div
                            class="Option"
                            v-for="(type, index) in typeOfCustomer"
                            :key="index"
                            @click="changeTypeOfCustomer(Object.keys(type)[1].toString())"
                        >
                            {{ type[Object.keys(type)[1].toString()] }}
                        </div>
                    </div>
                </div>
                <div class="ChartSegmentation mt-16">
                    <div class="TitleChartSegment">
                        {{ $t('CustomerReport.CustomerSegmentation') }}
                    </div>
                    <div class="ChartSegment mt-16">
                        <ChartSegmentation
                            :series="customerTypeSeries"
                            :listCustomerType="customerTypeChart"
                            class="chartStyles"
                        />
                    </div>
                </div>
                <div class="ChartSegmentation mt-32">
                    <div class="TitleChartSegment">
                        {{ $t('CustomerReport.CustomerAge') }}
                    </div>
                    <div class="ChartSegment mt-16">
                        <CustomerAge :series="customerAgeSeries" class="chartStyles" />
                    </div>
                </div>
                <div style="margin-top:59px" class="ChartSegmentation">
                    <div class="TitleChartSegment">
                        {{ $t('CustomerReport.CustomerGender') }}
                    </div>
                    <div class="ChartSegment mt-16">
                        <CustomerGender :series="genderChart" />
                    </div>
                </div>
                <div class="ChartRadar" style="margin-top:68px">
                    <div class="TitleChartRadar">
                        {{ $t('CustomerReport.CustomerSegment') }}
                        <div
                            class="btn-Standard"
                            style="cursor: pointer"
                            @click="showListCustomerSegment()"
                            v-click-outside="clickOutsideCustomerSegment"
                        >
                            {{ segmentSelected }}
                            <span class="material-icons ml-8">arrow_drop_down</span>
                            <div
                                class="wrapOptionRadar"
                                ref="showListCustomerSegment"
                                style="cursor: pointer; right: 0;"
                            >
                                <div
                                    class="Option"
                                    v-for="(segment, index) in listSegmentDefault"
                                    :key="index"
                                    @click="changeCustomerSegment(segment)"
                                >
                                    {{ segment }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ChartRadar mt-16">
                        <CustomerRadar :series="listSegmentAnalystData" :customer-segment-select="segmentSelected" />
                    </div>
                </div>
            </div>
        </div>
        <modal height="auto" name="customer-modal" @before-close="beforeCloseCustomerModal">
            <div class="header-img">
                <div>
                    <img
                        v-if="customerImageInModal !== ''"
                        :src="customerImageInModal"
                        alt="Italian Trulli"
                        height="50"
                    />
                </div>
                <div class="InputCustom" style="cursor: pointer;">
                    <span class="material-icons" style="cursor: pointer;">add</span>
                    <input type="file" @change="handleFileUpload($event)" style="cursor: pointer;" />
                    {{ $t('Customers.AddPicture') }}<span style="color: #ff5050">&nbsp;&nbsp;&#42;</span>
                </div>
            </div>
            <div class="sectionPogup">
                <div class="wrapSelectNamePhone">
                    <div class="SelectName">
                        <span class="LabelInput"
                            >{{ $t('Customers.FullName') }}<span style="color: #ff5050">&nbsp;&nbsp;&#42;</span></span
                        >

                        <input
                            type="text"
                            :placeholder="$t('Customers.TypeName')"
                            class="InputCustomPogup mt-8px"
                            v-model="customerNameInModal"
                        />
                        <span
                            style="display: flex; align-items: center; margin-left: 10px"
                            v-if="validateMessage['customerName']"
                        >
                            <span class="material-icons" style="color: #ff5050; font-size:1.0vw; margin-right: 3px"
                                >cancel</span
                            >
                            <span style="color: red; font-size:0.8vw; ">{{ validateMessage['customerName'] }}</span>
                        </span>
                    </div>
                    <div class="SelectPhone">
                        <span class="LabelInput">{{ $t('Customers.Phone') }}</span>
                        <input
                            type="text"
                            :placeholder="$t('Customers.TypeTelephone')"
                            class="InputCustomPogup mt-8px"
                            v-model="customerTelephoneInModal"
                        />
                        <span
                            style="display: flex; align-items: center; margin-left: 10px"
                            v-if="validateMessage['customerPhone']"
                        >
                            <span class="material-icons" style="color: #ff5050; font-size:1.0vw; margin-right: 3px"
                                >cancel</span
                            >
                            <span style="color: red; font-size:0.8vw; ">{{ validateMessage['customerPhone'] }}</span>
                        </span>
                    </div>
                </div>
                <div class="wrapSelectNamePhone mt-16">
                    <div class="SelectName">
                        <span class="LabelInput">{{ $t('Customers.Gender') }}</span>
                        <div
                            class="SelectCustomPogup mt-8px"
                            @click="chooseGenderOptionInModal()"
                            v-click-outside="handleClickOutSideGenderInModal"
                        >
                            {{ genderTitleInModal }}
                            <span class="material-icons">arrow_drop_down</span>
                            <div ref="wrapGenderOption" class="wrapOption">
                                <div class="Option" @click="changeGenderInModal('Male', 'Nam')">
                                    {{ $t('dashboard.Male') }}
                                </div>
                                <div class="Option" @click="changeGenderInModal('Female', 'Nữ')">
                                    {{ $t('dashboard.Female') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="SelectPhone">
                        <span class="LabelInput">{{ $t('Customers.DateOfBirth') }}</span>
                        <date-picker
                            @click.native="chooseTime()"
                            class="mt-8px"
                            v-model="rangeTime"
                            :placeholder="rangeTimeString"
                            :lang="langSelect === 'vn' ? 'vi' : 'en'"
                            v-click-outside="handleClickOutsideRangePicker"
                            format="DD/MM/YYYY"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="wrapSelectNamePhone mt-16">
                    <div class="SelectName width100">
                        <span class="LabelInput">{{ $t('Customers.Segment') }}</span>
                        <div
                            class="SelectCustomPogup mt-8px"
                            @click="chooseCustomerTypeOptionInModal()"
                            v-click-outside="handleClickOutSideCustomerTypeInModal"
                        >
                            {{ customerTypeTitleInModal }}
                            <span class="material-icons">arrow_drop_down</span>
                            <div class="wrapOptionSegment" ref="wrapCustomerTypeOption">
                                <div v-for="customerType in listCustomerTypeInAdd" :key="customerType.id">
                                    <div
                                        :ref="customerType.id"
                                        class="Option"
                                        @click="changeCustomerTypeInModal(customerType.name, customerType.id)"
                                    >
                                        {{ customerType.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="linkSeeHistory"
                @click="callGetCustomerRecordByCusID(customerIDInModal, customerNameInModal, customerImageInModal)"
            >
                {{ $t('Customers.ShowHistory') }}
            </div>
            <div class="BottomPogup">
                <div class="Btn-Close mr-16" @click="hideCustomerModal()">
                    {{ $t('Settings.Cancel') }}
                </div>
                <div class="Btn-Save" @click="callAPIAddCustomer()">
                    {{ $t('Customers.Submit') }}
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import { ChartBehaviour, ChartSegmentation, CustomerAge, CustomerGender, CustomerRadar } from '@/pages';
import * as moment from 'moment-timezone';
import { Api } from '@/api';

const customerBehaviorAnalyst = Api.get('customerBehaviorAnalyst');
const listCustomerByDateRange = Api.get('listCustomerByDateRange');
const listCustomerTypeAPI = Api.get('listCustomerType');
const downloadListCustomerInReport = Api.get('downloadListCustomerInReport');
const customerAgeAnalyst = Api.get('customerAgeAnalyst');
const customerTypeAnalystAndGender = Api.get('customerTypeAnalystAndGender');
const customerSegmentationRadar = Api.get('customerSegmentationRadar');
const getCustomerDetailV2 = Api.get('getCustomerDetailV2');
const updateCustomerAPI = Api.get('updateCustomerV2');

export default {
    components: {
        ChartBehaviour,
        ChartSegmentation,
        CustomerAge,
        CustomerGender,
        CustomerRadar,
    },
    watch: {
        rangeTime(val) {
            if (val === null) {
                this.rangeTimeString = '01/01/1996';
            } else {
                if (
                    moment(val)
                        .format('DD/MM/YYYY')
                        .toString() !==
                    moment()
                        .format('DD/MM/YYYY')
                        .toString()
                ) {
                    this.rangeTimeString = moment(val)
                        .format('DD/MM/YYYY')
                        .toString();
                } else {
                    this.rangeTimeString = '01/01/1996';
                }
            }
        },
    },
    data() {
        var self = this;
        return {
            listCustomerTypeInAdd: [],
            dateSelect: [
                { id: '1', type: 'month' },
                { id: '2', type: 'halfYear' },
                { id: '3', type: 'year' },
            ],
            show: false,
            typeOfDate: 'year',
            listCustomerInRange: [],
            langSelect: '',
            page: 1,
            limit: 6,
            totalPage: 1,
            genderChart: [],
            customerTypeChart: [],
            customerTypeSeries: [
                {
                    data: [],
                },
            ],
            customerAgeSeries: [
                {
                    data: [],
                },
            ],
            typeOfDateDisplay: '',
            objTypeOfDate: {
                month: '90 ' + (localStorage.lang === 'vn' ? 'ngày' : 'days'),
                halfYear: '180 ' + (localStorage.lang === 'vn' ? 'ngày' : 'days'),
                year: '365 ' + (localStorage.lang === 'vn' ? 'ngày' : 'days'),
            },
            typeCustomerDisplay: '',
            typeCustomerDisplayInListCustomer: '',
            typeCustomerSelected: {
                recent: this.$t('CustomerReport.Recent'),
                promising: this.$t('CustomerReport.Promising'),
                loyal: this.$t('CustomerReport.Loyal'),
                needAttention: this.$t('CustomerReport.NeedCare'),
                risk: this.$t('CustomerReport.Risk'),
                hibernating: this.$t('CustomerReport.GraduallyDisappear'),
                canNotLose: this.$t('CustomerReport.CanNotLose'),
                all: this.$t('CustomerReport.All'),
            },
            typeOfCustomer: [
                { id: '8', all: this.$t('CustomerReport.All') },
                { id: '1', recent: this.$t('CustomerReport.Recent') },
                { id: '2', promising: this.$t('CustomerReport.Promising') },
                { id: '3', loyal: this.$t('CustomerReport.Loyal') },
                { id: '4', needAttention: this.$t('CustomerReport.NeedCare') },
                { id: '5', risk: this.$t('CustomerReport.Risk') },
                { id: '6', hibernating: this.$t('CustomerReport.GraduallyDisappear') },
                { id: '7', canNotLose: this.$t('CustomerReport.CanNotLose') },
            ],
            customerBehaviorSelected: '',
            listDate: [],
            customerBehaviorDate: {
                year: ['0-30', '31-180', '181-365'],
                halfYear: ['0-30', '31-90', '91-180'],
                month: ['0-15', '16-45', '46-90'],
            },
            customerBehaviorBoundary: {
                year: ['1-2', '3-5', '6-9', '10+'],
                halfYear: ['1', '2-3', '4-5', '6+'],
                month: ['1', '2', '3-4', '5+'],
            },
            listCount: [],
            listType: ['risk', 'loyal', 'new', 'potentialLoyalty', 'needCare', 'graduallyDisappear', 'canNotLose'],
            listTypeValue: [],
            listTypePercent: [],
            listSegmentDefault: [],
            segmentSelected: '',
            listSegmentAnalystData: [
                {
                    name: '',
                    data: [],
                },
                {
                    name: '',
                    data: [],
                },
            ],

            customerIDInModal: '',
            customerImageInModal: '',
            rangeTime: moment('01/01/1996').format('DD/MM/YYYY'),
            rangeTimeString: moment('01/01/1996').format('DD/MM/YYYY'),
            customerNameInModal: '',
            customerTelephoneInModal: '',
            customerAgeInModal: '',
            customerGenderInModal: '',
            customerTypeInModal: '',
            imageFile: '',

            genderTitleInModal: self.$t('Customers.TypeGender'),
            customerTypeTitleInModal: self.$t('Customers.TypeCustomerType'),
            validateMessage: [],
            showRangePicker: false,
            typeCustomerInTable: '',
            selectedSegmentID: 'all',
        };
    },
    methods: {
        beforeCloseCustomerModal() {
            this.customerTypeTitleInModal = this.$t('Customers.TypeCustomerType');
            this.customerTypeInModal = '';
            this.genderTitleInModal = this.$t('Customers.TypeGender');
            this.customerGenderInModal = '';
            this.customerNameInModal = '';
            this.customerAgeInModal = '';
            this.customerTelephoneInModal = '';
            this.customerImageInModal = '';
            this.imageFile = '';
            this.rangeTimeString = '01/01/1996';
            this.rangeTime = '01/01/1996';
        },

        handleFileUpload(event) {
            const file = event.target.files[0];
            this.imageFile = file;
            this.customerImageInModal = URL.createObjectURL(file);
        },

        chooseGenderOptionInModal() {
            this.$refs['wrapGenderOption'].style.position = 'absolute';
            this.$refs['wrapGenderOption'].style.display = 'block';
            this.$refs['wrapGenderOption'].style.visibility = 'visible';
        },
        handleClickOutSideGenderInModal() {
            this.$refs['wrapGenderOption'].style.display = 'none';
            this.$refs['wrapGenderOption'].style.visibility = 'hidden';
        },

        changeGenderInModal(value) {
            var self = this;
            setTimeout(() => {
                self.handleClickOutSideGenderInModal();
            }, 100);
            this.customerGenderInModal = value.toLowerCase();
            this.genderTitleInModal = this.$t('dashboard.' + value);
        },

        handleClickOutsideRangePicker(index) {
            if (
                this.showRangePicker == true &&
                index.srcElement._prevClass != 'option rangeTime' &&
                index.srcElement._prevClass != 'mx-icon-right' &&
                index.srcElement._prevClass != 'mx-icon-left' &&
                index.srcElement._prevClass != 'mx-icon-double-left' &&
                index.srcElement._prevClass != 'mx-icon-double-right' &&
                index.srcElement._prevClass != 'mx-calendar-header' &&
                index.srcElement._prevClass != 'mx-btn mx-btn-text mx-btn-icon-double-left' &&
                index.srcElement._prevClass != 'mx-btn mx-btn-text mx-btn-icon-double-right' &&
                index.srcElement._prevClass != 'mx-btn mx-btn-text mx-btn-icon-left' &&
                index.srcElement._prevClass != 'mx-btn mx-btn-text mx-btn-icon-right' &&
                index.srcElement._prevClass != 'mx-calendar mx-calendar-panel-date'
            ) {
                if (navigator.userAgent.indexOf('Firefox') == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != 'mx-datepicker-content') {
                            this.showRangePicker = false;
                        }
                    } else {
                        this.showRangePicker = false;
                    }
                } else {
                    if (
                        index.target.parentElement._prevClass != 'cell' &&
                        index.target.parentElement._prevClass != 'cell active' &&
                        index.target.parentElement._prevClass != 'cell today active'
                    ) {
                        this.showRangePicker = false;
                    }
                }
            }
        },
        validateCustomerNameData(name) {
            if (name.length >= 1 && name.replace(/\s/g, '') === '') {
                this.validateMessage['customerName'] = this.$t('CustomerResponse.CM00002');
            } else {
                this.validateMessage['customerName'] = '';
            }
        },
        validatePhoneCustomer(phone) {
            if (phone !== '' && phone.length !== 10) {
                this.validateMessage['customerPhone'] = this.$t('CustomerResponse.CM00032');
            } else if (phone !== '' && !/^-?\d+$/.test(phone)) {
                this.validateMessage['customerPhone'] = this.$t('CustomerResponse.CM00031');
            } else {
                this.validateMessage['customerPhone'] = '';
            }
        },
        chooseTime() {
            this.showRangePicker = true;
        },

        showModal(customerID) {
            let self = this;
            getCustomerDetailV2.get(customerID).then(response => {
                const responseFromAPI = response.data;
                if (responseFromAPI.customerType !== 'Chưa có phân khúc') {
                    self.customerTypeTitleInModal = responseFromAPI.customerTypeName;
                    self.customerTypeInModal = responseFromAPI.customerType;
                }
                if (responseFromAPI.customerType === '') {
                    self.customerTypeTitleInModal = this.$t('CustomerReport.NoSegment');
                }

                self.customerNameInModal = responseFromAPI.name;
                self.customerAgeInModal = responseFromAPI.age;
                let dateOfBirth = this.parseDateString(responseFromAPI.birthDate);
                self.rangeTimeString = self.rangeTime = dateOfBirth;
                self.customerTelephoneInModal = responseFromAPI.telephone;
                self.customerImageInModal = responseFromAPI.image;
                self.customerIDInModal = customerID;

                if (responseFromAPI.gender === 'male') {
                    self.genderTitleInModal = self.$t('dashboard.Male');
                } else {
                    self.genderTitleInModal = self.$t('dashboard.Female');
                }
                self.customerGenderInModal = responseFromAPI.gender;
                self.editingCustomer = true;
                self.$modal.show('customer-modal');
            });
            if (Boolean(localStorage.getItem('currentCustomerData'))) {
                localStorage.removeItem('currentCustomerData');
                this.$router.replace({ query: null });
            }
        },
        parseDateString(dateString) {
            const [d, m, y] = dateString.split('/');
            return new Date([m, d, y].join('/'));
        },

        chooseCustomerTypeOptionInModal() {
            this.$refs['wrapCustomerTypeOption'].style.position = 'absolute';
            this.$refs['wrapCustomerTypeOption'].style.display = 'block';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'visible';
        },
        handleClickOutSideCustomerTypeInModal() {
            this.$refs['wrapCustomerTypeOption'].style.display = 'none';
            this.$refs['wrapCustomerTypeOption'].style.visibility = 'hidden';
        },

        changeCustomerTypeInModal(customerTypeName, customerTypeID) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideCustomerTypeInModal();
            }, 100);
            this.customerTypeInModal = customerTypeID;
            this.customerTypeTitleInModal = customerTypeName;
        },

        callGetCustomerRecordByCusID(cusID, cusName, cusImage) {
            this.$router.push({
                path: 'report-face',
                query: {
                    cusID: cusID,
                    report: true,
                },
            });
            localStorage.setItem(
                'currentCustomerData',
                JSON.stringify({
                    cusName: cusName,
                    cusImage: cusImage,
                    cusID: cusID,
                })
            );
        },

        callAPIAddCustomer() {
            this.customerAgeInModal = parseInt(this.customerAgeInModal);
            this.customerTelephoneInModal = this.customerTelephoneInModal.replaceAll(/\s/g, '');
            // remove validate customer name
            // change into using watch event to validate data
            this.$swal({
                title: this.$t('CustomerResponse.CM99999'),
                allowOutsideClick: true,
            });
            this.$swal.showLoading();
            let bodyFormData = new FormData();
            if (this.customerTypeInModal === this.$t('CustomerReport.NoSegment')) {
                this.customerTypeInModal = '';
            }
            if (this.customerTypeInModal === this.$t('Customers.TypeCustomerType')) {
                this.customerTypeInModal = '';
            }

            bodyFormData.append('birthDate', this.rangeTimeString);
            bodyFormData.append('gender', this.customerGenderInModal);
            bodyFormData.append('image', this.imageFile);
            bodyFormData.append('name', this.customerNameInModal.replace(/  +/g, ' '));
            bodyFormData.append('customerType', this.customerTypeInModal);
            if (this.customerTelephoneInModal !== null && Boolean(this.customerTelephoneInModal) === true) {
                bodyFormData.append('telephone', this.customerTelephoneInModal);
            }

            updateCustomerAPI
                .put(this.customerIDInModal, bodyFormData)
                .then(response => {
                    this.$swal.close();
                    this.callGetListCustomerByDateRange('', '', this.page, this.limit);
                    this.$swal(
                        this.$t('CustomerResponse.' + response.data.message.replace('.', '')),
                        '',
                        'success'
                    ).then(_ => {
                        this.hideCustomerModal();
                    });
                })
                .catch(error => {
                    if (error.response === undefined) {
                        this.$swal.close();
                        this.$swal(this.$t('CustomerResponse.CM99998'), '', 'warning');
                    } else {
                        this.$swal.close();
                        this.$swal(
                            this.$t('CustomerResponse.' + error.response.data.message.replace('.', '')),
                            '',
                            'warning'
                        );
                    }
                });
        },

        hideCustomerModal() {
            this.$modal.hide('customer-modal');
        },
        changeCustomerSegment(segment) {
            this.segmentSelected = segment;

            let getCurrentTypeOfCustomer = Object.keys(this.typeCustomerSelected)
                .toString();
            if (segment === 'No Segment' || segment === 'Chưa có phân khúc') {
                segment = 'blanks';
                segmentID = '';
            }
            if (segment === 'Tất cả' || segment === 'All') {
                segment = '';
                segmentID = 'all';
            }

            this.selectedSegmentID = segmentID;
            customerTypeAnalystAndGender
                .get(this.typeOfDate, this.customerBehaviorSelected, this.selectedSegmentID)
                .then(resp => {
                    let listSegmentAnalystData = [
                        {
                            name: '',
                            data: [],
                        },
                        {
                            name: '',
                            data: [],
                        },
                    ];
                    const genderRadarChart = resp.data['genderRadar'];
                    for (const male of genderRadarChart['male']) {
                        listSegmentAnalystData[0].data.push(male[Object.keys(male).toString()]);
                    }
                    for (const female of genderRadarChart['female']) {
                        listSegmentAnalystData[1].data.push(female[Object.keys(female).toString()]);
                    }
                    listSegmentAnalystData[1].name = localStorage.lang === 'vn' ? 'Nữ' : 'Female';
                    listSegmentAnalystData[0].name = localStorage.lang === 'vn' ? 'Nam' : 'Male';
                    this.listSegmentAnalystData = listSegmentAnalystData;
                });
        },
        changeDateType(typeOfDateSelected) {
            this.typeOfDateDisplay = this.objTypeOfDate[typeOfDateSelected];
            if (typeOfDateSelected === 'all') typeOfDateSelected = '';
            this.typeOfDate = typeOfDateSelected;
            this.callAPIWhenTypeOfDateChange(typeOfDateSelected);
        },
        changeTypeOfCustomer(typeOfCustomerSelected) {
            this.$refs['typeOfCustomerOption'].style.display = 'none';
            this.$refs['typeOfCustomerOption'].style.visibility = 'hidden';
            this.typeCustomerDisplay = this.typeCustomerSelected[typeOfCustomerSelected];
            this.callAPIWhenTypeOfCustomerChange(typeOfCustomerSelected);
        },
        showDateRange() {
            this.$refs['dateRangeOption'].style.position = 'absolute';
            this.$refs['dateRangeOption'].style.display = 'block';
            this.$refs['dateRangeOption'].style.visibility = 'visible';
        },
        showTypeOfCustomerListCustomer() {
            this.$refs['typeCustomerOpt'].style.position = 'absolute';
            this.$refs['typeCustomerOpt'].style.display = 'block';
            this.$refs['typeCustomerOpt'].style.visibility = 'visible';
        },
        showTypeOfCustomer() {
            this.$refs['typeOfCustomerOption'].style.position = 'absolute';
            this.$refs['typeOfCustomerOption'].style.display = 'block';
            this.$refs['typeOfCustomerOption'].style.visibility = 'visible';
        },
        showListCustomerSegment() {
            this.$refs['showListCustomerSegment'].style.position = 'absolute';
            this.$refs['showListCustomerSegment'].style.display = 'block';
            this.$refs['showListCustomerSegment'].style.visibility = 'visible';
        },
        clickOutsideCustomerSegment(index) {
            if (index) {
                this.$refs['showListCustomerSegment'].style.display = 'none';
                this.$refs['showListCustomerSegment'].style.visibility = 'hidden';
            }
        },
        clickOutsideTypeOfDate(index) {
            if (index) {
                this.$refs['dateRangeOption'].style.display = 'none';
                this.$refs['dateRangeOption'].style.visibility = 'hidden';
            }
        },
        clickOutsideListCustomer(index) {
            if (index) {
                this.$refs['typeCustomerOpt'].style.display = 'none';
                this.$refs['typeCustomerOpt'].style.visibility = 'hidden';
            }
        },
        clickOutsideSelectTypeOfCustomer(index) {
            if (index) {
                this.$refs['typeOfCustomerOption'].style.display = 'none';
                this.$refs['typeOfCustomerOption'].style.visibility = 'hidden';
            }
        },
        changeTypeInListCustomer(typeSelect) {
            this.typeCustomerDisplayInListCustomer = this.typeCustomerSelected[typeSelect];
            if (typeSelect === 'all') {
                typeSelect = '';
            }
            this.page = 1;
            this.typeCustomerInTable = typeSelect;
            listCustomerByDateRange.get(this.typeOfDate, typeSelect, this.page, this.limit).then(response => {
                if (response.data['listCustomer'].length === 0) {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t('dashboard.NoData'),
                        icon: 'add_alert',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'primary',
                    });
                }
                this.listCustomerInRange = response.data['listCustomer'];
                this.totalPage = parseInt(response.data['totalPage']);
                this.$refs['typeCustomerOpt'].style.display = 'none';
                this.$refs['typeCustomerOpt'].style.visibility = 'hidden';
            });
        },
        callAPIWhenTypeOfCustomerChange(typeSelected) {
            this.typeCustomerDisplay = this.typeCustomerSelected[typeSelected];
            if (typeSelected === 'all') {
                typeSelected = '';
            }
            this.customerBehaviorSelected = typeSelected;
            customerTypeAnalystAndGender.get(this.typeOfDate, typeSelected, this.selectedSegmentID).then(resp => {
                let customerTypeCountChange = [
                    {
                        data: [],
                    },
                ];
                let customerTypeChartChange = [];
                let customerTypeArray = resp.data['countCustomerByType'].filter(
                    el => !['male', 'female'].includes(el['customerType'])
                );
                for (const customerType of customerTypeArray) {
                    if (customerType['customerType'] === 'blanks') {
                        customerType['customerType'] = this.$t('CustomerReport.NoSegment');
                    }
                    customerTypeCountChange[0].data.push(customerType['count']);
                    customerTypeChartChange.push(customerType['customerType']);
                }
                this.customerTypeSeries = customerTypeCountChange;
                this.customerTypeChart = customerTypeChartChange;

                // gender
                let genderArray = resp.data['countCustomerByType'].filter(el =>
                    ['male', 'female'].includes(el['customerType'])
                );
                let genderChange = [];
                for (const gen of genderArray) {
                    genderChange.push(gen['count']);
                }
                this.genderChart = genderChange;

                let customerAgeSeries = [
                    {
                        data: [],
                    },
                ];
                for (const age of resp.data['sexRatio']) {
                    customerAgeSeries[0].data.push(age['count']);
                }
                this.customerAgeSeries = customerAgeSeries;

                this.$refs['showListCustomerSegment'].style.display = 'none';
                this.$refs['showListCustomerSegment'].style.visibility = 'hidden';
                let listSegmentAnalystData = [
                    {
                        name: '',
                        data: [],
                    },
                    {
                        name: '',
                        data: [],
                    },
                ];
                const genderRadarChart = resp.data['genderRadar'];
                for (const male of genderRadarChart['male']) {
                    listSegmentAnalystData[0].data.push(male[Object.keys(male).toString()]);
                }
                for (const female of genderRadarChart['female']) {
                    listSegmentAnalystData[1].data.push(female[Object.keys(female).toString()]);
                }
                listSegmentAnalystData[1].name = localStorage.lang === 'vn' ? 'Nữ' : 'Female';
                listSegmentAnalystData[0].name = localStorage.lang === 'vn' ? 'Nam' : 'Male';
                this.listSegmentAnalystData = listSegmentAnalystData;
            });
            let customerTypeSelect;
            if (this.segmentSelected === this.$t('CustomerReport.NoSegment')) {
                customerTypeSelect = 'blanks';
            } else if (this.segmentSelected === 'Tất cả' || this.segmentSelected === 'All') {
                customerTypeSelect = '';
            } else {
                customerTypeSelect = this.segmentSelected;
            }
        },
        callAPIWhenTypeOfDateChange(timeSelected) {
            this.typeCustomerDisplay = this.$t('CustomerReport.All');
            this.typeCustomerDisplayInListCustomer = this.$t('CustomerReport.All');
            customerBehaviorAnalyst.get(timeSelected).then(response => {
                this.$refs['dateRangeOption'].style.display = 'none';
                this.$refs['dateRangeOption'].style.visibility = 'hidden';
                this.listDate = this.customerBehaviorDate[timeSelected];
                this.listCount = this.customerBehaviorBoundary[timeSelected];

                const { risk, loyal, recent, promising, needAttention, hibernating, canNotLose } = response.data;
                this.listTypeValue = [risk, loyal, recent, promising, needAttention, hibernating, canNotLose];
                const totalPeople = this.listTypeValue.reduce(
                    (previousValue, currentValue) => previousValue + currentValue,
                    0
                );
                let percent;
                let percentArray = [];
                for (const data of this.listTypeValue) {
                    if (data > 0) {
                        percent = ((data / totalPeople) * 100).toFixed(2);
                    } else percent = 0;
                    percentArray.push(percent);
                }
                this.listTypePercent = percentArray;
            });
            this.segmentSelected = this.$t('CustomerReport.All');
            customerTypeAnalystAndGender
                .get(timeSelected, this.customerBehaviorSelected, this.selectedSegmentID)
                .then(resp => {
                    // customer type
                    let customerTypeArray = resp.data['countCustomerByType'].filter(
                        el => !['male', 'female'].includes(el['customerType'])
                    );
                    let customerTypeCount = [];
                    let customerTypeValue = [];
                    for (const customerType of customerTypeArray) {
                        if (customerType['customerType'] === 'blanks') {
                            customerType['customerType'] = this.$t('CustomerReport.NoSegment');
                        }
                        customerTypeCount.push(customerType['count']);
                        customerTypeValue.push(customerType['customerType']);
                    }
                    this.customerTypeSeries[0].data = customerTypeCount;
                    this.customerTypeChart = customerTypeValue;
                    // gender
                    let genderArray = resp.data['countCustomerByType'].filter(el =>
                        ['male', 'female'].includes(el['customerType'])
                    );
                    let genderData = [];
                    for (const gen of genderArray) {
                        genderData.push(gen['count']);
                    }
                    this.genderChart = genderData;

                    let customerAgeSeries = [
                        {
                            data: [],
                        },
                    ];
                    for (const age of resp.data['sexRatio']) {
                        customerAgeSeries[0].data.push(age['count']);
                    }
                    this.customerAgeSeries = customerAgeSeries;

                    let listSegmentAnalystData = [
                        {
                            name: '',
                            data: [],
                        },
                        {
                            name: '',
                            data: [],
                        },
                    ];
                    const genderRadarChart = resp.data['genderRadar'];
                    for (const male of genderRadarChart['male']) {
                        listSegmentAnalystData[0].data.push(male[Object.keys(male).toString()]);
                    }
                    for (const female of genderRadarChart['female']) {
                        listSegmentAnalystData[1].data.push(female[Object.keys(female).toString()]);
                    }
                    listSegmentAnalystData[1].name = localStorage.lang === 'vn' ? 'Nữ' : 'Female';
                    listSegmentAnalystData[0].name = localStorage.lang === 'vn' ? 'Nam' : 'Male';
                    this.listSegmentAnalystData = listSegmentAnalystData;
                });
        },
        downloadListCustomerByDateRange() {
            if (this.listCustomerInRange.length === 0) {
                this.$swal(this.$t('dashboard.NoData'), '', 'warning');
            } else {
                this.$swal({
                    title: this.$t('CustomerResponse.CM99999'),
                    allowOutsideClick: true,
                });
                this.$swal.showLoading();
                let typeRequests = Object.keys(this.typeCustomerSelected).find(
                    key => this.typeCustomerSelected[key] === this.typeCustomerDisplayInListCustomer
                );
                if (typeRequests === 'all') typeRequests = '';
                downloadListCustomerInReport
                    .post(this.typeOfDate, typeRequests, this.langSelect)
                    .then(responseData => {
                        this.$swal.close();
                        const url = URL.createObjectURL(
                            new Blob([responseData.data], {
                                type: 'application/vnd.ms-excel',
                            })
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            `${this.$t('CustomerReport.ExcelFileName')} - ${
                                this.typeCustomerDisplayInListCustomer
                            }.xlsx`
                        );
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(err => {
                        if (err) this.$swal.close();
                    });
            }
        },
        callGetListCustomerByDateRange(time, type, page, limit) {
            listCustomerByDateRange.get(time, type, page, limit).then(resp => {
                if (resp.data['listResponse'] === 0) {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t('dashboard.NoData'),
                        icon: 'add_alert',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'primary',
                    });
                } else {
                    this.listCustomerInRange = resp.data['listCustomer'];
                    this.totalPage = parseInt(resp.data['totalPage']);
                }
            });
        },
        changePage(num) {
            let typeCustomerParams = Object.keys(this.typeCustomerSelected).find(
                key => this.typeCustomerSelected[key] === this.typeCustomerDisplayInListCustomer
            );
            if (typeCustomerParams === 'all') typeCustomerParams = '';
            if (num === -1 && this.page !== 1) {
                this.page = this.page - 1;
                this.callGetListCustomerByDateRange(this.typeOfDate, typeCustomerParams, this.page, this.limit);
            }
            // back to first page
            if (num === 0 && this.page > 1) {
                this.page = 1;
                this.callGetListCustomerByDateRange(this.typeOfDate, typeCustomerParams, this.page, this.limit);
            }
            if (num === 1) {
                this.page = this.page < this.totalPage ? this.page + 1 : this.page;
                this.callGetListCustomerByDateRange(this.typeOfDate, typeCustomerParams, this.page, this.limit);
            }
            // through to last page
            if (num === parseInt(this.totalPage)) {
                this.page = parseInt(this.totalPage);
                this.callGetListCustomerByDateRange(this.typeOfDate, typeCustomerParams, this.page, this.limit);
            }
        },
        handleErrorLoadImage(e) {
            e.target.src = '/img/default-avatar.png';
        },
    },
    mounted() {
        if (this.$route.query.detail === true && Boolean(localStorage.getItem('currentCustomerData'))) {
            const data = JSON.parse(localStorage.getItem('currentCustomerData'));
            this.showModal(data['cusID']);
        }
        customerBehaviorAnalyst.get('year').then(responseData => {
            this.listDate = this.customerBehaviorDate['year'];
            this.listCount = this.customerBehaviorBoundary['year'];
            const { risk, loyal, recent, promising, needAttention, hibernating, canNotLose } = responseData.data;
            this.listTypeValue = [risk, loyal, recent, promising, needAttention, hibernating, canNotLose];
            const totalPeople = this.listTypeValue.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
            );
            for (const data of this.listTypeValue) {
                let percent;
                if (data > 0) {
                    percent = ((data / totalPeople) * 100).toFixed(2);
                } else percent = 0;
                this.listTypePercent.push(percent);
            }
        });

        listCustomerByDateRange.get('year', '', this.page, this.limit).then(response => {
            if (response.data['listCustomer'].length === 0) {
                this.$notify({
                    timeout: 2500,
                    message: this.$t('dashboard.NoData'),
                    icon: 'add_alert',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'primary',
                });
            }
            this.listCustomerInRange = response.data['listCustomer'];
            this.totalPage = parseInt(response.data['totalPage']);
        });
        this.langSelect = localStorage.lang;
        this.typeCustomerDisplay = this.$t('CustomerReport.All');
        this.typeCustomerDisplayInListCustomer = this.$t('CustomerReport.All');
        this.segmentSelected = this.$t('CustomerReport.All');
        this.typeOfDateDisplay = this.langSelect === 'vn' ? '365 ngày' : '365 days';

        customerTypeAnalystAndGender.get('year', this.customerBehaviorSelected, this.selectedSegmentID).then(resp => {
            let customerTypeArray = resp.data['countCustomerByType'].filter(
                el => !['male', 'female'].includes(el['customerType'])
            );
            let customerTypeSeries = [
                {
                    data: [],
                },
            ];
            let customerTypeChart = [];
            for (const customerType of customerTypeArray) {
                if (customerType['customerType'] === 'blanks') {
                    customerType['customerType'] = this.$t('CustomerReport.NoSegment');
                }
                customerTypeSeries[0].data.push(customerType['count']);
                customerTypeChart.push(customerType['customerType']);
            }

            this.customerTypeChart = customerTypeChart;
            this.listSegmentDefault = [this.langSelect === 'vn' ? 'Tất cả' : 'All'].concat(this.customerTypeChart);
            this.customerTypeSeries = customerTypeSeries;

            // gender
            let genderArray = resp.data['countCustomerByType'].filter(el =>
                ['male', 'female'].includes(el['customerType'])
            );
            let genderChart = [];
            for (const gen of genderArray) {
                genderChart.push(gen['count']);
            }
            this.genderChart = genderChart;

            let customerAgeSeries = [
                {
                    data: [],
                },
            ];
            for (const age of resp.data['sexRatio']) {
                customerAgeSeries[0].data.push(age['count']);
            }
            this.customerAgeSeries = customerAgeSeries;

            let listSegmentAnalystData = [
                {
                    name: '',
                    data: [],
                },
                {
                    name: '',
                    data: [],
                },
            ];
            const genderRadarChart = resp.data['genderRadar'];
            for (const male of genderRadarChart['male']) {
                listSegmentAnalystData[0].data.push(male[Object.keys(male).toString()]);
            }
            for (const female of genderRadarChart['female']) {
                listSegmentAnalystData[1].data.push(female[Object.keys(female).toString()]);
            }
            listSegmentAnalystData[1].name = localStorage.lang === 'vn' ? 'Nữ' : 'Female';
            listSegmentAnalystData[0].name = localStorage.lang === 'vn' ? 'Nam' : 'Male';
            this.listSegmentAnalystData = listSegmentAnalystData;
        });

        listCustomerTypeAPI.get().then(response => {
            let listCustomerType = response.data['listCustomerTypeResponse'];

            this.listCustomerTypeInAdd = listCustomerType.filter(s => s.name !== 'Chưa có phân khúc');
            for (const customerTypeData of listCustomerType) {
                if (localStorage?.lang === 'en' && customerTypeData?.name === 'Chưa có phân khúc') {
                    customerTypeData['name'] = 'No Segment';
                }
            }
        });
    },
};
</script>

<style lang="scss">
.wrapPage {
    .Title-Customer-Report {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
    }
    /* Tooltip container */
    .tooltip {
        position: relative;
        display: flex;
        align-items: center;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 272px;
        position: absolute;
        height: 50px;
        border-radius: 6px;
        background-color: #ffffff;
        text-align: center;
        padding: 10px 0;

        /* Position the tooltip text - see examples below! */
        z-index: 1;

        left: 43px;
        right: 289px;

        /* Caption Regular */

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */

        color: #000000;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .Choosetime-Customer {
        padding: 16px 24px;
        border: 1px solid #276eff;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #276eff;
        width: fit-content;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        //&:hover{
        .wrapOption {
            display: block;
        }

        //}

        .wrapOption {
            position: absolute;
            background: #ffffff;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            min-width: 200px;
            top: 58px;
            z-index: 9;
            display: none;

            .Option {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                padding: 16px;
                color: #333333;
                cursor: pointer;

                &:hover {
                    background-color: #edf3ff;
                }
            }
        }
    }

    .wrapContent {
        display: grid;
        width: 100%;
        grid-template-columns: 65% 1fr;
        row-gap: 0;
        column-gap: 24px;

        .Content-Left {
            .Behaviour {
                background: #ffffff;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 610px;
                border-radius: 16px;
                padding: 24px;

                .TitleChart {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    align-items: center;
                    display: flex;
                    color: #000000;

                    .help {
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        color: #bfbfbf;
                        cursor: pointer;
                        border-radius: 8px;

                        &:hover {
                            background-color: #edf3ff;
                        }

                        .SubTitle {
                            padding: 12px 16px;
                            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                            border-radius: 8px;
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            position: absolute;
                            text-align: center;
                            top: 0;
                            left: 42px;
                            width: 280px;
                            color: #333333;
                            display: none;
                        }
                    }
                }
            }

            .TableCustomer {
                padding: 24px;
                background: #ffffff;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                border-radius: 16px;
                height: 651px;
                position: relative;
                .headerTable {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    margin-top: 5px;
                    .titleTableCustomer {
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #000000;
                    }
                    .wrapButton {
                        display: flex;
                        align-items: center;
                        .BTN-Download {
                            padding: 12px 12px;
                            height: 48px;
                            color: #8c8c8c;
                        }
                        .BTN-ChooseOption {
                            position: relative;
                            padding: 12px 16px;
                            font-family: 'Roboto';
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            border: 1px solid #8c8c8c;
                            border-radius: 8px;
                            .wrapOption {
                                position: absolute;
                                background: #ffffff;
                                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                                display: flex;
                                flex-direction: column;
                                min-width: 200px;
                                top: 58px;
                                left: -70px;
                                z-index: 9;
                                display: none;

                                .Option {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    padding: 16px;
                                    color: #333333;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #edf3ff;
                                    }
                                }
                            }
                        }
                    }
                }

                table {
                    border-spacing: 0 !important;
                    width: 100%;
                    border: solid 1px #f0f0f0;
                }

                .tr-head {
                    height: 80px;
                    background-color: #064b6c;
                    color: #fff;

                    th {
                        width: 200px;

                        .material-icons {
                            vertical-align: middle;
                        }
                    }

                    th:nth-child(2) {
                        text-align: center;
                        // padding-left: 14px;
                    }
                }

                td {
                    text-align: center;
                    height: 64px;
                    padding: 8px 12px;
                    font-family: 'Roboto';
                    font-size: 14px;
                    color: #272833;
                    font-weight: 400;
                    border-bottom: solid 1px #f0f0f0;

                    img {
                        width: 48px;
                        height: 48px;
                        margin-right: 8px;
                    }
                }

                td:first-child {
                    min-width: 200px;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                td:nth-child(2) {
                    width: 48px !important;
                    text-align: center;
                    // margin-left: 5px;
                }

                td:last-child {
                    .material-icons {
                        cursor: pointer;
                    }
                }

                td:nth-child(6) {
                    max-width: 64px !important;
                }

                .pagination {
                    display: inline-block;
                    border-radius: 5px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 24px;
                    bottom: 4px;
                    .wrapSelectPagination {
                        display: flex;
                        align-items: center;
                        position: relative;

                        .seeTable {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            font-family: 'Roboto';
                        }

                        .SelectPagination {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 60px;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            font-family: 'Roboto';
                            padding: 8px 8px;
                            cursor: pointer;
                            border-radius: 8px;
                            margin-left: 16px;
                            // &:hover {
                            //     background-color: #F6F9FF;
                            //     .wrapOptionPagination {
                            //         display: block;
                            //     }
                            // }
                            .wrapOptionPagination {
                                position: absolute;
                                display: none;
                                padding: 8px;
                                width: 60px;
                                background: #ffffff;
                                box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
                                border-radius: 10px;
                                top: 40px;
                                right: 0;
                                z-index: 9;

                                .Option {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    padding: 8px 16px;
                                    width: 100%;
                                    height: 36px;
                                    font-family: 'Roboto';
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #333333;
                                    cursor: pointer;
                                }

                                .OptionActive {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    padding: 8px 16px;
                                    width: 100%;
                                    height: auto;
                                    font-family: 'Roboto';
                                    background-color: #edf3ff;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #333333;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .pagination a {
                    color: black;
                    float: left;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'Roboto';
                    padding: 12px 16px;
                    text-decoration: none;
                }

                .pagination a.active {
                    color: #2b4bf2;
                    border-radius: 5px;
                }

                .pagination a:hover:not(.active) {
                    background-color: #f6f9ff;
                    border-radius: 5px;
                    color: #fff;
                }
            }
        }

        .Content-Right {
            background: #ffffff;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            height: 1302px;

            .NeedAttention {
                padding: 12px 16px;
                border: 1px solid #8c8c8c;
                border-radius: 8px;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1f1f1f;
                display: flex;
                align-items: center;
                width: fit-content;
                cursor: pointer;
                position: relative;

                .wrapOption {
                    display: block;
                }

                .wrapOption {
                    position: absolute;
                    background: #ffffff;
                    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    min-width: 175px;
                    top: 52px;
                    z-index: 9;
                    display: none;

                    .Option {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 16px;
                        color: #333333;
                        cursor: pointer;

                        &:hover {
                            background-color: #edf3ff;
                        }
                    }
                }
            }

            .ChartSegmentation {
                height: 232px;

                .TitleChartSegment {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                }

                .ChartSegment {
                    height: 192px;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;

                    .chartStyles {
                        position: absolute;
                        top: -28px;
                        left: -16px;
                        width: 106%;
                    }
                }
            }

            .ChartRadar {
                .TitleChartRadar {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .btn-Standard {
                        padding: 12px 16px;
                        border: 1px solid #8c8c8c;
                        border-radius: 8px;
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1f1f1f;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        cursor: pointer;
                        position: relative;
                        .wrapOption {
                            display: block;
                        }

                        .wrapOptionRadar {
                            position: absolute;
                            background: #ffffff;
                            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                            display: flex;
                            flex-direction: column;
                            min-width: max-content;
                            top: 52px;
                            z-index: 9;
                            display: none;
                            max-height: 300px;
                            overflow-y: auto;
                            .Option {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                padding: 16px;
                                color: #333333;
                                cursor: pointer;
                                max-width: 250px;
                                overflow-x: hidden;
                                text-overflow: ellipsis;

                                &:hover {
                                    background-color: #edf3ff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.header-img {
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 100px;
        margin-bottom: 16px;
        height: 100px;
    }
    .InputCustom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        padding: 16px 16px;
        height: 40px;
        border: 1px dashed #276eff;
        box-sizing: border-box;
        border-radius: 8px;
        color: #276eff;
        font-family: Roboto;
        width: auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        input {
            width: 148px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
}
.sectionPogup {
    width: 100%;
    padding: 24px;
    .wrapSelectNamePhone {
        display: flex;
        justify-content: space-between;
        .SelectName {
            display: flex;
            flex-direction: column;
            width: calc(50% - 12px);
        }
        .SelectPhone {
            display: flex;
            flex-direction: column;
            width: calc(50% - 12px);
            .mx-datepicker {
                height: 48px;
                border: 1px solid rgba(86, 103, 137, 0.26);
                box-sizing: border-box;
                border-radius: 8px;
                width: 100% !important;
                display: flex;
                align-items: center;
                .mx-input-wrapper {
                    height: 100% !important;
                    width: 100% !important;
                    border-radius: 8px;
                    .mx-input {
                        height: 100% !important;
                        width: 100% !important;
                        border-radius: 8px;
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
.LabelInput {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #2b4bf2;
}
.InputCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    &::placeholder {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8c8c8c;
    }
}
.SelectCustomPogup {
    height: 48px;
    border: 1px solid rgba(86, 103, 137, 0.26);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    position: relative;
    .wrapOption {
        position: absolute;
        z-index: 9999;
        display: none;
        padding: 8px;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 50px;
        left: -25px;
        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }
    .wrapOptionSegment {
        position: absolute;
        display: none;
        padding: 8px;
        width: fit-content;
        height: 125px;
        overflow: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 52px;
        right: -100px !important;
        z-index: 9999;
        max-height: 312px;
        overflow: auto;
        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: Roboto;
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }
    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
}
.linkSeeHistory {
    padding: 0px 24px 16px;
    width: 100%;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #107c10;
    cursor: pointer;
}
.BottomPogup {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    padding: 24px;
    .Btn-Close {
        padding: 6px 16px;
        width: 104px;
        height: 42px;
        background: #f0f0f0;
        border-radius: 8px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .Btn-Save {
        padding: 6px 16px;
        width: 104px;
        height: 42px;
        background: #276eff;
        border-radius: 8px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
</style>
