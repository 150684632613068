<template>

  <div class="detail-report-main-container-behaviour">

    <div class="detail-report-container">
      <div class="detail-report-title">{{ $t("Employee.Analysis") }}</div>
      <GreetingReport :listGroup="listGroup"></GreetingReport>
    </div>
  </div>
</template>

<script>
import {GreetingReport} from "@/components"
import {Api} from "@/api/index";

const getListGroup = Api.get("getListGroupV2");
export default {
  name: "BehaviorStatistic",
  components: {GreetingReport},
  data() {
    return {
      listGroup: [],
    }
  },
  mounted() {
    getListGroup.get().then((response) => {
      let listGroup = response.data["listResponse"];
      var allGroup = localStorage.lang == "vn" ? "Tất cả" : "All"
      listGroup.unshift({id: "", name: allGroup});
      this.listGroup = listGroup;
    });
  },
  methods: {

  }
}
</script>

<style lang="scss">
.detail-report-main-container-behaviour {

  .select-area {
    width: 20%;
    margin-left: auto;
  }

  .note-conversion-rate {
    padding-left: 25px;

    h4 {
      margin: 0;
      color: #ff6459;
      font-weight: bold;
    }

    ul > li {
      font-size: 15px;
      color: #7f7f7f;
      margin: 20px 0 0 40px;
    }
  }

}

.detail-report-title {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: 1280px) {
  .detail-report-main-container {
    gap: 30px;

    .note-conversion-rate {
      ul > li {
        margin: 13px 0 0 30px;
      }
    }
  }
}

#wrapper_1 {
  background-color: pink;
  height: 100px;
  float: left;
  width: 100px;
}

#wrapper_2 {
  background-color: brown;
  height: 100px;
  width: 100px;
  float: right;
}

.seperator {
  width: 10px;
  background: black;
}

.vl {
  border-left: 1px solid black;
  height: 100%;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.container {
  position: relative;
}

.clearfix {
  clear: both;
}
</style>
