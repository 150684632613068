<template>
    <div>
        <div class="customer-detail-container">
            <div class="md-layout">
                <div class="md-layout-item md-size-75">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-20">
                            <img
                                style="width: 80%; padding: 0%"
                                :src="customerImage"
                            />
                        </div>
                        <div class="md-layout-item md-size-80" style="padding: 0%">
                            <h2 style="margin-top:0%; margin-bottom:0%">{{customerName}} <span style="font-size:15px"> ({{gender}} - {{age}})</span></h2>
                            <p style="margin-top:0%; margin-bottom:5%">{{customerType}}</p>
                            <div
                                class="btn-employee btn-add-employee"
                                @click="addNewCustomer"
                                style="cursor: pointer;"
                            >
                                {{ $t('Customers.Edit') }}
                            </div>
                            <div
                                class="btn-employee btn-comeback"
                                @click="cancelEdit"
                                style="margin-left: 2%; cursor: pointer;"
                            >
                                {{ $t('Customers.Cancel') }}
                            </div>

                        </div>
                        <div class="md-layout-item md-size-33" style="margin-top:4%; padding:0px;">
                            <div class="md-layout">
                                <div class="md-layout-item md-size-20" style="padding:10px;">
                                    <img
                                        src="/img/firstTime.png"
                                    />
                                </div>
                                <div v-if="listItem.length>0" class="md-layout-item md-size-80" style="padding:10px;">
                                    {{ $t('Customers.FirstVisited') }}
                                    
                                    <p style="margin-bottom:-6%"><b>{{listItem[listItem.length-1].date.split(", ")[0]}}</b></p>
                                    <p><b>{{listItem[listItem.length-1].date.split(", ")[1]}}</b></p>
                                </div>
                                <div v-else class="md-layout-item md-size-80" style="padding:10px;">
                                    {{ $t('Customers.FirstVisited') }}
                                
                                </div>
                                
                            </div>
                        </div>
                        <div class="md-layout-item md-size-33" style="margin-top:4%; padding:0px;">
                            <div class="md-layout">
                                <div class="md-layout-item md-size-20" style="padding:10px;">
                                    <img
                                        src="/img/demographics.png"
                                    />
                                </div>
                                <div class="md-layout-item md-size-80" style="padding:10px;">
                                    {{ $t('Customers.NumberOfVisits') }}
                                    
                                    <p style="margin-bottom:-6%"><b>{{listItem.length}}</b></p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="md-layout-item md-size-33" style="margin-top:4%; padding:0px;">
                            <div class="md-layout">
                                <div class="md-layout-item md-size-20" style="padding:10px;">
                                    <img
                                        src="/img/icon.png"
                                    />
                                </div>
                                <div class="md-layout-item md-size-80" style="padding:10px;">
                                    {{this.$t("Customers.Phone")}}
                                    
                                    <p style="margin-bottom:-6%"><b>{{phoneNumber}}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md-layout-item md-size-5">
                    <div class="vertical_dotted_line"></div>
                </div>
                
            </div>
        </div>
        <br>
        <div class="customer-history-container">
            <div class="manage-customer-header">
                <div class="manage-customer-title">{{ this.$t("Customers.CustomerHistory") }}</div>
                <div style="overflow-y: scroll; width:100%; height:500px; padding-top:20px;">
                    <div v-for="(value, name) in listItem" :key="name" class="md-layout">
                        <div class="md-layout-item md-size-20" style="padding-top:20px; padding-left:2%; padding-right:2%">
                            <img
                                :src="value.currentImage"
                            />
                        </div>
                        <div class="md-layout-item md-size-80" style="padding:10px;">
                            {{ $t('Customers.VisitStore') }} {{ value.groupName}}
                            
                            <p style="margin-bottom:-6%"><b>{{ value.date }}</b></p>
                        </div>
                        <div class="md-layout-item md-size-100" style="padding:5px;">
                            <hr style="border-top: dotted 0.5px #8E8EA1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { Api } from "@/api/index";

const customerHistory = Api.get("customerHistory");
const getCustomerRecordDetail = Api.get("getCustomerRecordDetail");
export default {
    methods: {
        cancelEdit() {
            this.$router.push({
                name: "Báo cáo nhận diện khách hàng",
                query: {
                    dateStart: this.$route.query.dateStart,
                    dateEnd: this.$route.query.dateEnd
                }
            });
        },
        addNewCustomer(val) {
            if (this.customerID !== "") {
                this.$router.push({
                    name: "Sửa khách hàng",
                    query: {
                        customerID: this.customerID,
                        recordID: this.$route.query.recordID, 
                        dateString: this.$route.query.dateString,
                        groupName: this.$route.query.groupName,
                        dateStart: this.$route.query.dateStart,
                        dateEnd: this.$route.query.dateEnd
                    }
                });
            } else {
                this.$router.push({
                name: "Xem khách hàng",
                    query: { 
                        recordID: this.$route.query.recordID, 
                        dateString: this.$route.query.dateString,
                        groupName: this.$route.query.groupName,
                        dateStart: this.$route.query.dateStart,
                        dateEnd: this.$route.query.dateEnd
                    }
                })
            }
            
        },
        getCustomerHistory() {
            if (this.customerID != "") {

                customerHistory.get(this.customerID).then(response => {
                    if (localStorage.lang == "vn") {
                        for (let i = 0; i < response.data.historyData.length; i++) {
                            var recordTrans = response.data.historyData[i]
                            if (recordTrans.date.split(" ")[1] == "Monday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 2 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Tuesday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 3 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Wednesday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 4 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Thursday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 5 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Friday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 6 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Saturday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 7 " +  recordTrans.date.split(" ")[2]
                            } else {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " chủ nhật " +  recordTrans.date.split(" ")[2]
                            }
                            this.listItem.push(recordTrans)
                        }
                    } else {
                        this.listItem = response.data.historyData
                    }
                })
            } else {
                customerHistory.get(this.$route.query.recordID).then(response => {
                    if (localStorage.lang == "vn") {
                        for (let i = 0; i < response.data.historyData.length; i++) {
                            var recordTrans = response.data.historyData[i]
                            if (recordTrans.date.split(" ")[1] == "Monday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 2 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Tuesday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 3 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Wednesday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 4 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Thursday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 5 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Friday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 6 " +  recordTrans.date.split(" ")[2]
                            } else if (recordTrans.date.split(" ")[1] == "Saturday") {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " thứ 7 " +  recordTrans.date.split(" ")[2]
                            } else {
                                recordTrans.date = recordTrans.date.split(" ")[0] + " chủ nhật " +  recordTrans.date.split(" ")[2]
                            }
                            this.listItem.push(recordTrans)
                        }
                    } else {
                        this.listItem = response.data.historyData
                    }
                    
                    
                })
            }
        }
    },
    mounted() {
        getCustomerRecordDetail.get(this.$route.query.dateString, this.$route.query.groupName, this.$route.query.recordID).then(response => {
            // this.dataInfoGuest = {
            //     age: response.data.response.age,
            //     gender: response.data.response.gender,
            //     name: response.data.response.customerName,
            //     telephone: response.data.response.telephoneNumber,
            //     customerType: response.data.response.customerType
            // }
            // this.avatarEmp = response.data.response.currentImage
            // this.newCustomer = response.data.response.newCustomer

            this.customerName = response.data.response.customerName
            if (this.customerName == 'Khách mới' && localStorage.lang=="en") {
                this.customerName = "New customer"
            }
            if (response.data.response.gender === "male" && localStorage.lang=="vn") {
                this.gender = "Nam"
            } else if ((response.data.response.gender === "female" && localStorage.lang=="vn")) {
                this.gender = "Nữ"
            } else {
                this.gender = response.data.response.gender.charAt(0).toUpperCase() + response.data.response.gender.slice(1)
            }
            this.age = response.data.response.age
            this.customerType = response.data.response.customerType
            this.phoneNumber = response.data.response.telephoneNumber
            this.customerImage = response.data.response.image
            this.customerID = response.data.response.customerID
            this.getCustomerHistory()
        })
    },
    data() {
        return {
            customerName: "Nguyễn Văn A",
            gender: "Nam",
            age: "23",
            customerType: "Khách quen",
            phoneNumber: "0339941497",
            customerImage: "/img/user.png",
            customerID: "",
            listItem: [
            ]
        }
    }
}
</script>

<style lang="scss">
.customer-detail-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}
.btn-add-employee {
    background-color: #6370E3;
    color: white;
    gap: 20px;
    width: 20%;
    min-width: 80px;
    height: 25%;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #6370E3;
    }
}
.btn-comeback {
    background-color: #8E8EA1;
    color: white;
    gap: 20px;
    width: 20%;
    min-width: 80px;
    height: 25%;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #8E8EA1;
    }
}
.vertical_dotted_line
{
    border-left: 1px dotted #8E8EA1;
    height: 100%;
}

.customer-history-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    gap: 20px;
    width: 30%
}

.manage-customer-header {
    justify-content: space-between;

    .manage-customer-title {
        font-size: 25px;
        font-weight: bold;
    }
}

</style>