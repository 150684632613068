<template>
    <div class="heat-map-dashboard">
        <div v-if="isDashboard==true"
             class="heatmap-container"
             v-bind:id="'layout'"
             style="
             margin: 0 auto;
             width: 100%;
             height: 500px; 
             z-index: 0;
             border-radius: 5px;
             "
             ref="layout"
        >
            <div
                :class="tooltip"
                style="
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.8);
            color: white;
            font-size: 14px;
            padding: 5px;
            line-height: 18px;
            z-index: 999!important;
            display: block;
          "
            ></div>
        </div>
        <div v-if="isDashboard==false"
             class="heatmap-container"
             v-bind:id="'layout'"
             style="
             margin: 0 auto;
             width: 70%;
             max-width: 800px;
             height: 500px; 
             z-index: 0;
             border-radius: 5px;
             "
             ref="layout"
        >
            <div
                :class="tooltip"
                style="
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.8);
            color: white;
            font-size: 14px;
            padding: 5px;
            line-height: 18px;
            z-index: 999!important;
            display: block;
          "
            ></div>
        </div>

        <div :id="idHeatmapLayoutLegend" style="padding-top: 1rem">
            <span :id="'min-layout'" style="margin: 3px"></span>
            <img :id="'gradient-layout'" src="" style="width: 16rem" alt=""/>
            <span :id="'max-layout'" style="margin: 3px"></span>
        </div>
    </div>
</template>

<script>
import HeatmapOverlay from "heatmap.js/plugins/leaflet-heatmap";
import L from "leaflet";

export default {
    components: {},
    props: {
        heatmapData: {},
        isDashboard: Boolean
    },
    data() {
        return {
            url: "",
            minZoom: -2,
            bounds: [
                [-1002, 0],
                [0, 835]
            ],
            heatmapLayer: null,
            map: null,
            layout: "layout",
            heatmapOverlayConfig: {
                radius: 2,
                maxOpacity: 0,
                scaleRadius: true,
                useLocalExtrema: false,
                latField: "y",
                lngField: "x",
                valueField: "value"
            },
            gradientConfig: {
                1: "rgb(255,0,0)",
                0.25: "rgb(0,0,255)",
                0.55: "rgb(0,255,0)",
                0.85: "yellow"
            },
            idHeatmapLayoutLegend: "heatmap-layout-legend",
            heatPane: "heatPane",
            tooltip: "tooltip",
            isFullScreen: false,
            max: 0,
            min: -1,
            isEmptyHeatmapResponse: true
        };
    },
    methods: {
        drawLeafletHeatmap() {
            const layoutID = `${this.layout}`;

            if (this.map) {
                this.map.remove();
            }

            let heatmapData = this.heatmapData;

            let data = {
                max: heatmapData.max,
                data: heatmapData.data,
                min: -1
            };
            this.max = heatmapData.max;
            if (heatmapData.layoutSize) {
                let layoutSize = heatmapData.layoutSize;
                this.bounds = [
                    [-layoutSize[1], 0],
                    [0, layoutSize[0]]
                ];
            }
            let baseLayer = L.imageOverlay(heatmapData.url, this.bounds, {
                zIndex: -1
            });

            this.map = L.map(layoutID, {
                center: [25.6586, -80.3568],
                zoom: -1.3,
                minZoom: -2,
                maxZoom: 2,
                crs: L.CRS.Simple,
                fullscreenControl: false,
                attributionControl: false
            });
            this.map.createPane(this.heatPane);
            baseLayer.addTo(this.map);

            this.heatmapLayer = new HeatmapOverlay(this.heatmapOverlayConfig);
            this.heatmapLayer["overlayPane"] = this.heatPane;
            this.heatmapLayer.addTo(this.map);
            this.heatmapLayer.setData(data);

            let heatPane = this.map.getPanes(this.heatPane).overlayPane;
            let heatmapInstance = this.heatmapLayer._heatmap;

            this.showTooltip(heatPane, heatmapInstance);

            L.control
                .scale({maxWidth: 200, metric: false, imperial: false})
                .addTo(this.map);

            this.map.setMaxBounds(this.bounds);

            L.control
                .fullscreen({
                    position: "bottomright"
                })
                .addTo(this.map);

            this.map.zoomControl.setPosition("bottomright");

            // let baseLayers = {
            //     "heatmapLayer": this.heatmapLayer,
            //     "OpenStreetMap": baseLayer
            // }
            //
            // L.control.layers(baseLayers).addTo(this.map)
            this.updateHeatmapLegend(heatmapData.max, 0);
        },
        calculateXValue() {
            let screenSize = window.innerWidth;
            if (screenSize >= 1920) {
                return 50;
            }

            return parseInt((screenSize * 100) / 1920) - 50;
        },
        showTooltip(heatPane, heatmapInstance) {
            const tooltip = document.querySelector(".tooltip");
            let map = this.map;
            let max = this.max;
            const self = this;
            heatPane.onmousemove = function (e) {
                let displayValue = heatmapInstance.getValueAt({
                    x: e["layerX"],
                    y: e["layerY"]
                });
                let colorValue = (255 * displayValue) / (max + 1);
                if (colorValue <= 5) {
                    displayValue = 0;
                }

                if (displayValue > 0) {
                    displayValue = displayValue - 1;
                }

                tooltip.style.display = "block";
                tooltip.style.position = "absolute";

                let pageXAddition = self.calculateXValue();

                if (!map.isFullscreen()) {
                    tooltip.style.left = e.layerX + pageXAddition + "px";
                    tooltip.style.top = e.layerY + "px";
                } else {
                    tooltip.style.left = e.layerX + 50 + "px";
                    tooltip.style.top = e.layerY + "px";
                }

                tooltip.zIndex = 999;
                displayValue = new Intl.NumberFormat().format(displayValue)

                tooltip.innerHTML =
                    displayValue + " " + self.$t("Report.Second");
            };

            heatPane.onmouseout = function (e) {
                if (!map.isFullscreen()) {
                    tooltip.style.display = "none";
                }
            };
        },
        updateHeatmapLegend(max, min) {
            let legendCanvas = document.createElement("canvas");
            legendCanvas.width = 100;
            legendCanvas.height = 10;

            let legendCtx = legendCanvas.getContext("2d");
            let gradientCfg = {};

            max = new Intl.NumberFormat().format(max)

            document.getElementById("min-layout").innerHTML = min + "s";
            document.getElementById("max-layout").innerHTML = max + "s";

            gradientCfg = this.gradientConfig;
            let gradient = legendCtx.createLinearGradient(0, 0, 100, 1);
            gradient.addColorStop(0.05, "white")
            for (let key in gradientCfg) {
                gradient.addColorStop(key - 0.1, gradientCfg[key]);
            }

            legendCtx.fillStyle = gradient;
            legendCtx.fillRect(0, 0, 100, 10);

            document.getElementById(
                "gradient-layout"
            ).src = legendCanvas.toDataURL();
        }
    },
    watch: {
        heatmapData(val) {
            if (val["url"]) {
                this.isEmptyHeatmapResponse = false
            }
            this.drawLeafletHeatmap();
        }
    }
};
</script>

<style scoped>
.heat-map-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.height-auto {
    height: auto !important;
}
</style>


